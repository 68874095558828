import React, { useState, FC, useContext, useEffect } from 'react';
import { Space, Swiper } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux';
import MapContext from '../../Map';
import mapboxgl from 'mapbox-gl';
import { setAllFloors, setFloor } from '../../../../redux/actions/mapActions';
import { Button, Input, Popover } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import Search, { SearchProps } from 'antd/es/input/Search';
import store from '../../../../redux/store';
interface FloorSelectorProps {
  floors: string[]
};
const FloorSelector: FC = () => {
  // const currentFloor = useSelector((state: any) => state.floor)
  // const floors = useSelector((state: any) => state.allFloors)
  const [floors, setFloors] = useState(useSelector((state: any) => state.allFloors))
  const [curfloor, setCurFloor] = useState(useSelector((state: any) => state.floor))
  const map = useSelector((state: any) => state.currentMap)
  store.subscribe(()=>{
    setFloors(store.getState().allFloors)
    setCurFloor(store.getState().floor)
  })
  const [items, setItems] = useState(floors.map((floor: any, index: any) => (
    <Swiper.Item key={index} className='w-7 h-7 text-center rounded-md leading-7'
      style={{ background: curfloor === floor ? '#f97316' : '#FFF' }}
      onClick={() => handleFloorChange(floor)}
    >
      <div style={{ color: curfloor === index ? '#FFF' : '#9E9E9E' }}>
        {floor}
      </div>
    </Swiper.Item>
  )))
  const dispatch = useDispatch()
  const handleFloorChange = (floor: string) => {
    dispatch(setFloor(floor))
    setCurFloor(floor)
    // map?.setLayoutProperty(floor, 'visibility', 'visible')
    // map?.setLayoutProperty(floor+'_label', 'visibility', 'visible')
  }

  useEffect(()=>{
    setItems(floors.map((f: any, index: any) => (
      <Swiper.Item key={index} className='w-7 h-7 text-center rounded-md'
        style={{ background: curfloor === f ? '#f97316' : '#FFF' }}
        onClick={() => handleFloorChange(f)}
      >
        <div style={{ color: curfloor === index ? '#FFF' : '#9E9E9E',lineHeight:'1.75rem' }}>
          {f}
        </div>
      </Swiper.Item>
    )))
  },[floors.length,curfloor])
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    if(value === '')
    return;
    // 遍历floors数组查找是否有相同的值
    const existingFloorIndex = floors.findIndex((floor: string) => floor === value);
  
    if (existingFloorIndex === -1) { // 如果没有找到相同的值
      // floors.push(value);
      let Floors = [...floors, value];
      dispatch(setAllFloors(Floors));
    } else {
      // 可以在这里加入提示信息或处理已存在楼层的情况
      console.log('楼层名称已存在');
    }
  };

  const content = (
    <Search
    placeholder="输入楼层名称"
    allowClear
    enterButton="添加"
    size="large"
    onSearch={onSearch}
  />
  );

  return (
    <div className='w-7 h-28  rounded-md absolute inset-y-1/2 left-1.5 shadow-xl bg-white z-50'>
      <Swiper
        className='w-7 h-28 '
        direction='vertical'
        // trackOffset={15}
        slideSize={30}
        defaultIndex={0}
        loop={true}
        stuckAtBoundary={false}
        indicator={() => null}
        style={{'--height': '6rem'}}
      >
        {items}
      </Swiper>
      {/* <Popover content={content} title="添加楼层" trigger="click" className='top-32'>
        <Button type="primary">添加楼层</Button>
      </Popover> */}
    </div>
  )
};

export default FloorSelector;
