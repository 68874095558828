import { mean } from "mathjs";
import { calculateDirection, convertToMercator, getDistance, toMercator } from "../../utils/agos";

const math = require('mathjs')
export class KnnLocator {
    FP = []; // 指纹库
    beacons: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined = undefined;
    err = 4;
    distances: { distance: number, coord: [number, number], item: any }[] = [];
    key: string[] = [];
    beaconPrint: { key: string, value: number, coord: [number, number] }[] = [];
    heading = 0;
    constructor(FP?: any, beacons?: any) {
        this.FP = FP;
        this.beacons = beacons;
    }
    start(data: any): [number, number] {
        if (!this.beacons && this.FP.length === 0)
            return [0, 0];
        let beacon = JSON.parse(data) //实时指纹向量
        let coord = [0, 0];
        const rssiMap: { key: string, value: number }[] = [];
        const optionalRssi: { key: string, value: number }[] = [];
        for (const key in beacon) {
            if (this.beacons)
                for (let i = 0; i < this.beacons?.features.length; i++)
                    if (this.beacons.features[i].properties?.majorminor === key) {
                        let mean = beacon[key].reduce((acc: any, val: any) => acc + val, 0) / beacon[key].length;
                        if (mean > -70)
                            rssiMap.push({ key: key, value: mean })
                        else if (mean > -80)
                            optionalRssi.push({ key: key, value: mean })
                    }
        }
        this.beaconPrint = [];
        let optionalBeaconPrint = [];
        if (this.beacons) {
            for (let j = 0; j < optionalRssi.length; j++) {
                for (let i = 0; i < this.beacons?.features.length; i++) {
                    if (this.beacons.features[i].properties?.majorminor === optionalRssi[j].key) {
                        let geometry = this.beacons.features[i].geometry as any;
                        // Beacoord[j] = geometry.coordinates;
                        optionalBeaconPrint.push({ key: optionalRssi[j].key, value: optionalRssi[j].value, coord: geometry.coordinates })
                    }
                }
            }
            for (let j = 0; j < rssiMap.length; j++) {
                for (let i = 0; i < this.beacons?.features.length; i++) {
                    if (this.beacons.features[i].properties?.majorminor === rssiMap[j].key) {
                        let geometry = this.beacons.features[i].geometry as any;
                        // Beacoord[j] = geometry.coordinates;
                        this.beaconPrint.push({ key: rssiMap[j].key, value: rssiMap[j].value, coord: geometry.coordinates })
                    }
                }
            }
            let sum = 0
            let dist = [];
            for (let i = 0; i < this.beaconPrint.length; i++) {
                if ( this.beaconPrint[i].value >= -62) {
                    dist[i] = -0.1111111111111111 * this.beaconPrint[i].value - 4.888888888888888
                }
                else if ( this.beaconPrint[i].value >= -64) {
                    dist[i] = -0.5 * this.beaconPrint[i].value - 29
                }
                else {
                    dist[i] = 4;
                }
            }
            for (let i = 0; i < dist.length ; i++){
                sum += (1/dist[i])
            }
            for (let i = 0; i < dist.length; i++) {
                coord[0] += (1/dist[i])* this.beaconPrint[i].coord[0] / sum;
                coord[1] += (1/dist[i])* this.beaconPrint[i].coord[1] / sum;
            }
            for(let i = 0; i < this.beaconPrint.length; i++){
                if(Math.abs(calculateDirection(convertToMercator(coord as any), convertToMercator(this.beaconPrint[i].coord as any)) - this.heading) % 180 > 110){
                    this.beaconPrint[i].value += 8;
                }
            }
            for (let i = 0; i < optionalBeaconPrint.length; i++) {
                if (Math.abs(calculateDirection(convertToMercator(coord as any), convertToMercator(optionalBeaconPrint[i].coord as any)) - this.heading) % 180 > 110) {
                    optionalBeaconPrint[i].value += 8;
                    if(optionalBeaconPrint[i].value > -70){
                        this.beaconPrint.push(optionalBeaconPrint[i]);
                    }
                }
            }
            this.beaconPrint.sort((a, b) => b.value - a.value);
            sum = 0
            dist = [];
            for (let i = 0; i < this.beaconPrint.length; i++) {
                if ( this.beaconPrint[i].value >= -62) {
                    dist[i] = -0.1111111111111111 * this.beaconPrint[i].value - 4.888888888888888
                }
                else if (this.beaconPrint[i].value >= -64) {
                    dist[i] = -0.5 * this.beaconPrint[i].value - 29
                }
                else {
                    dist[i] = 4;
                }
            }
            for (let i = 0; i < dist.length ; i++){
                sum += 1/dist[i]
            }
            coord = [0, 0]
            for (let i = 0; i < dist.length; i++) {
                coord[0] += (1/dist[i])* this.beaconPrint[i].coord[0] / sum;
                coord[1] += (1/dist[i])* this.beaconPrint[i].coord[1] / sum;
            }
            if(isNaN(coord[0]) || isNaN(coord[1]))
                coord = [0, 0];
            return coord as any;
            // Math.abs(calculateDirection(convertToMercator(coord as any), convertToMercator(Beacoord[1] as any)) - this.heading) % 180 > 110


        }


        return [0, 0]

    }

    getErr() {
        return this.err;
    }
    getDistances() {
        return this.distances
    }
    getKey() {
        return this.key
    }
    getBeaconPrint() {
        return this.beaconPrint;
    }
    set(FP: any, beacons: any) {
        this.beacons = beacons;
        this.FP = FP;
    }
    setHeading(heading: number) {
        this.heading = heading;
    }


}