import React, { useState } from 'react'
import { Button, FloatingBubble, Popover, Toast } from 'antd-mobile'
import { Action } from 'antd-mobile/es/components/popover'
import {
  AntOutline,
  HandPayCircleOutline,
  ScanningOutline,
  TransportQRcodeOutline,
  UpCircleOutline 
} from 'antd-mobile-icons'
import BLEScan from './BLEScan'
import CreateFingerprint from './createFingerprint'
import Fingerprint from './fingerprint'
import { coord } from '../../../utils/type'
import Test from './test'
import DataAnalyse from './analyse'
import Threebox from './3D'
import AccuracyTest from './accuracyTest'

export default () => {
    const[mode, setMode] = useState('')
    const [start, setStart] = useState<coord|undefined>(undefined)
    const [end, setEnd] = useState<coord|undefined>(undefined)
    const actions: Action[] = [
        { key: 'scan', icon: <ScanningOutline />, text: '蓝牙部署', onClick: () => {setMode('scan')}},
        { key: 'collection', icon: <HandPayCircleOutline />, text: '指纹采集', onClick: () => {setMode('collection')} },
        { key: 'create', icon: <TransportQRcodeOutline />, text: '生成指纹', onClick: () => {setMode('create')} },
        { key: 'analyse', icon: <AntOutline />, text: '数据分析', onClick: () => {setMode('analyse')} },
        {key: 'test',  icon: <AntOutline />, text: '定位测试', onClick: ()=> {setMode('test')}},
        {key: '3D',  icon: <AntOutline />, text: '添加3D模型', onClick: ()=> {setMode('3D')}},
        {key: 'accuracy',  icon: <AntOutline />, text: '定位精度', onClick: ()=> {setMode('accuracy')}}
      ]


  return (
    <>
    <FloatingBubble
    className='z-50'
        axis='xy'
        magnetic='x'
        style={{
          '--initial-position-bottom': '24px',
          '--initial-position-left': '24px',
          '--edge-distance': '24px',
        }}
      >
        <Popover.Menu
          actions={actions}
          placement='bottom-start'
        //   onAction={}
          trigger='click'
        >
          <UpCircleOutline />
        </Popover.Menu>
      </FloatingBubble>
      {
        mode === 'scan' && <BLEScan setCancel={()=>setMode('')} />
      }
      {
        mode === 'create' && <CreateFingerprint setCancel={()=>setMode('')} />
      }
      {
        mode === 'collection' && <Fingerprint setCancel={()=>setMode('')} />
      }
      {
        mode === 'test' && <Test setCancel={()=>setMode('')} />
      }
      {
        mode === 'analyse' && <DataAnalyse />
      }
      {
        mode === '3D' && <Threebox setCancel={()=>setMode('')}/>
      }
      {
        mode === 'accuracy' && <AccuracyTest setCancel={()=>setMode('')}/>
      }
    </>
  )
}