import React, { FC, useEffect, useState } from 'react'
import { Button, Input, List, Switch } from 'antd-mobile'
import {
    UnorderedListOutline,
    PayCircleOutline,
    SetOutline,
} from 'antd-mobile-icons'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import ObsClient from 'esdk-obs-browserjs'
import { List as VirtualizedList, AutoSizer } from 'react-virtualized'
// import { useLocation } from 'react-router-dom'

function calculateAverage(arr: any) {
    const sum = arr.reduce((acc: any, val: any) => acc + val, 0);
    return sum / arr.length;
}

export interface scanProps {
    setCancel: () => void;
}
const Scan: FC<scanProps> = ({ setCancel }) => {
    const [beacons, setBeacons] = useState<any>([])
    const location = useLocation();
    const [selected, setSelected] = useState<string | undefined>(undefined)
    const map = useSelector((state: any) => state.map.map as mapboxgl.Map)
    const obsClient = new ObsClient({
        //推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险。
        //您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
        access_key_id: 'TWRHS7BEB4P4SZCS63AB',
        secret_access_key: 'kISdQfc6igXQ8jvd1dpqSuJArPq60ucPHbmidkr8',
        server: 'https://obs.cn-south-1.myhuaweicloud.com'
    });
    useEffect(() => {
        getBeacon();
    }, [])
    useEffect(() => {
        console.log(map)
        if (!map)
            return;
        map.on('move', move)
    }, [map, selected])
    const move = () => {
        if (selected === undefined)
            return;
        const center = map.getCenter();
        (map.getSource('center') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [center.lng, center.lat],
                },
                properties: {
                    title: 'center',
                },
            }],
        })
    }
    const submit = () => {
        if (selected === undefined)
            return;
        const center = map.getCenter();
        const beacon = (map.getSource('beacons') as any)._data.features.filter((item: any) => item.properties['major-minor'] === selected)
        if (beacon.length !== 0) {
            setSelected(undefined)
            return;
        }
        (map.getSource('beacons') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [
                ...(map.getSource('beacons') as any)._data.features,
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [center.lng, center.lat],
                    },
                    properties: {
                        'majorminor': selected,
                    },
                }],
        });
        (map.getSource('center') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [],
        });
        setSelected(undefined)
        map.off('move', move)
        console.log(selected)
    }

    const upload = () => {
        obsClient.putObject({
            Bucket: 'mitu',
            Key: 'HUTB/beacons.json',
            Body: (map.getSource('beacons') as any)._data
        }, (err: any, result: any) => {
            if (err) {
                console.error('Error-->' + err);
            } else {
                console.log('Status-->' + result.CommonMsg.Status);
            }
        });
    }
    const getBeacon = () => {
        let hash = location.hash
        let data = hash.substring(1);
        if (data === '')
            return;
        const beacons = JSON.parse(decodeURIComponent(data))
        console.log(beacons)
        // {"1000-13954":[-58],"2307-14368":[-50], 'timestamp':1614518659000}
        for (const key in beacons) {
            if (key === 'timestamp' || key === 'compassDirection')
                continue;
            let mean = beacons[key].reduce((acc: any, val: any) => acc + val, 0) / beacons[key].length
            beacons[key] = mean;
        }
        delete beacons.timestamp;
        delete beacons.compassDirection;
        const sortedEntries = Object.entries(beacons)
            .sort((a, b) => {
                const avgA = a[1] as number;
                const avgB = b[1] as number;
                return avgB - avgA;
            });
        setBeacons(sortedEntries);
        (map.getSource('center') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [],
        });

    }

    const Cancel = () => {
        setSelected(undefined);
        (map.getSource('center') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [],
        })
        map.off('move', move)
        setCancel();
    }


    function rowRenderer(props: any): any {
       return beacons.map((beacon: any) => {
               return (
                   <List.Item key={beacon[0]} description={`rssi: ${beacon[1]}`} onClick={() => { setSelected(beacon[0]) }}>
                       {beacon[0]}
                   </List.Item>)

           })
    }

    return (
        <div className='z-50 absolute bottom-0 w-full bg-white'>
            {selected === undefined && <>
            {/* <List header='蓝牙列表' className='z-50 absolute bottom-0 w-full'>
                {
                    beacons.map((beacon: any) => {
                        return (
                            <List.Item key={beacon[0]} description={`rssi: ${beacon[1]}`} onClick={() => { setSelected(beacon[0]) }}>
                                {beacon[0]}
                            </List.Item>)

                    })
                }
            </List> */}
                <List header='蓝牙列表' className='z-50 absolute bottom-0 w-full'>
                    <AutoSizer disableHeight>
                        {({ width }: { width: number }) => (
                            <VirtualizedList
                                rowCount={50}
                                rowRenderer={rowRenderer}
                                width={width}
                                height={220}
                                rowHeight={30}
                                overscanRowCount={10}
                            />
                        )}
                    </AutoSizer>
                </List>
                <Button className='w-1/3 z-50' color='primary' onClick={getBeacon} >刷新</Button>
                <Button className='w-1/3 z-50' color='primary' onClick={upload}>上传</Button>
                <Button className='w-1/3 z-50' color='warning' onClick={Cancel}>取消</Button>
            </>
            }
            {
                selected !== undefined && <div className='w-full'>
                    <Input className='py-1.5' defaultValue={selected} style={{ '--font-size': '30px' }} />
                    <Button className='w-1/2' color='primary' onClick={submit}>确定</Button>
                    <Button className='w-1/2 ' color='warning' onClick={Cancel}>取消</Button>
                </div>
            }
        </div>
    )
}
export default Scan;