import { Button, Card, Space } from "antd-mobile";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { KNN } from "../../locator/KNN";
import { KnnLocator } from "../../locator/KnnLocator";
import { getBeacons, getFingerprints } from "../../../utils/request";
import Locator from "../../locator/locator";
import { re } from "mathjs";
import { getDistance, toMercator } from "../../../utils/agos";

export interface props {
    setCancel: () => void;
}
let knn: KNN;
let knnLocator: KnnLocator;
let coord: [number, number] = [0, 0];
let coords: [number, number][] = [];
let coords2: [number, number][] = [];
let coords3: [number, number][] = [];
let coords1: [number, number][] = [];
const locator = new Locator();
const AccuracyTest: FC<props> = ({ setCancel }) => {
    const map = useSelector((state: any) => state.map.map as mapboxgl.Map);
    const marker = useSelector((state: any) => state.map.marker as mapboxgl.Marker);
    const [err, setErr] = useState(-9999);
    const [err1, setErr1] = useState(-9999);
    const location = useLocation();
    const state = useRef<string>()

    useEffect(() => {
        getFingerprints('HUTB').then((fingerprints) => {
            getBeacons("HUTB").then((beacons) => {
                knn = new KNN(fingerprints, beacons);
                knnLocator = new KnnLocator();
                knnLocator.set(fingerprints, beacons);
            })
        })
    }, [])
    useEffect(() => {
        console.log("1", state.current);
        if (!knn || !map || !(state.current === 'knn' || state.current === 'select'))
            return;
        if (state.current === 'select') {
            map.on('click', setCoord);
        }
        let hash = location.hash
        let data = hash.substring(1);
        if (data === '')
            return;
        if (state.current === 'knn') {
            let urlData = JSON.parse(decodeURIComponent(data));
            knn.setHeading(urlData['compassDirection']);
            coord = knn.start(decodeURIComponent(data));
            if (coord[0] !== 0 && coord[1] !== 0) {
                // 初始化 x 和 y 的总和
                coords2.push(coord);
                let sumX = coord[0];
                let sumY = coord[1];
                let j = 1;
                for (let i = coords2.length - 1; i >= 0 && j < 4; i--, j++) {
                    sumX += coords2[i][0];
                    sumY += coords2[i][1];
                }
                sumX /= j; sumY /= j;
                coords.push([sumX, sumY]);
                if(coords.length > 15){
                    coords.shift();
                }
                console.log("coords:",coords);
            }
            knnLocator.setHeading(urlData['compassDirection']);
            let coord1 = knnLocator.start(decodeURIComponent(data));
            if (coord1[0] !== 0 && coord1[1] !== 0) {
                coords3.push(coord1);
                // 初始化 x 和 y 的总和
                let sumX = coord1[0];
                let sumY = coord1[1];
                let j = 1;
                for (let i = coords3.length - 1; i >= 0 && j < 4; i--, j++) {
                    sumX += coords3[i][0];
                    sumY += coords3[i][1];
                }
                sumX /= j; sumY /= j;
                coords1.push([sumX, sumY]);
                if(coords1.length > 15){
                    coords1.shift();
                }
                console.log("coords1",coords1);
            }
            const origin = [marker.getLngLat().lng, marker.getLngLat().lat];
            // 初始化 x 和 y 的总和
            let sumX = 0;
            let sumY = 0;

            // 遍历 BLCoords，累加 x 和 y
            for (const [x, y] of coords) {
                sumX += x;
                sumY += y;
            }

            // 计算 x 和 y 平均值
            let averageX = sumX / coords.length;
            let averageY = sumY / coords.length;
            setErr(getDistance(toMercator(origin as any), toMercator([averageX, averageY] as any)));
            // 初始化 x 和 y 的总和
            sumX = 0;
            sumY = 0;

            // 遍历 BLCoords，累加 x 和 y
            for (const [x, y] of coords1) {
                sumX += x;
                sumY += y;
            }

            // 计算 x 和 y 平均值
            averageX = sumX / coords1.length;
            averageY = sumY / coords1.length;
            setErr1(getDistance(toMercator(origin as any), toMercator(([averageX, averageY] as any))));
            (map.getSource('test') as mapboxgl.GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'MultiPoint',
                            coordinates: coords as any,
                        },
                        properties: {

                        }
                    }],
            });
            (map.getSource('test1') as mapboxgl.GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'MultiPoint',
                            coordinates: coords1 as any,
                        },
                        properties: {

                        }
                    }],
            });
        }
        if (coord[0] === 0 && coord[1] === 0)
            return;
        // setErr(`误差：${knn.getErr()}, 实时指纹：${JSON.stringify(knn.getKey())}, 匹配指纹： ${JSON.stringify(knn.getDistances())}`);
        (map.getSource('beacons') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [],
                    },
                    properties: {

                    }
                }],
        });
        if (map.getLayer('3D_layer')) {
            map.removeLayer('3D_layer');
            console.log('图层已删除');
        }
    }, [location, state.current]);
    const setCoord = (e: any) => {
        const coord = [e.lngLat.lng, e.lngLat.lat] as any;
        marker.setLngLat(coord);
    }
    const Cancel = () => {
        (map.getSource('beacons') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [],
        })
        setCancel();
    }
    return (
        <div className="z-40 absolute bottom-0 w-full">
            <Card title='精度测试'>
                <div className="text-center">
                    <div className="text-lg">原始定位误差：{err}</div>
                    <div className="text-lg">信号补偿后定位误差：{err1}</div>
                </div>
                <Space className="w-full">
                    <Button color='primary' onClick={() => { state.current = 'select' }}>选择测试点</Button>
                    <Button color='primary' onClick={() => { state.current = 'knn' }}>开始测试</Button>
                    <Button color='primary' onClick={() => { state.current = ''; map.off('click', setCoord); }}>取消</Button>
                    <Button onClick={Cancel}>返回</Button>
                </Space>
            </Card>
        </div>
    )
}
export default AccuracyTest;


