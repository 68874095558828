import { Coord } from "@turf/turf";
import { Card, Space, Image, Radio, Slider, Button } from "antd-mobile";
import { FC, useEffect, useRef, useState } from "react";
import desk from '../../../assets/desk.png'
import { useSelector } from "react-redux";
import ObsClient from 'esdk-obs-browserjs'
import { get3D } from "../../../utils/request";

export interface scanProps {
    setCancel: () => void;
}
const Threebox: FC<scanProps> = ({ setCancel }) => {
    const [icon, setIcon] = useState(0);
    const [references, setReferences] = useState<any>()
    const [center, setCenter] = useState<Coord>([0, 0])
    const [lastAngle, setLastAngle] = useState(0)
    const [angle, setAngle] = useState(0)
    const models = useRef<any[]>([]);
    const map = useSelector((state: any) => state.map.map as mapboxgl.Map);
    const obsClient = new ObsClient({
        //推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险。
        //您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
        access_key_id: 'TWRHS7BEB4P4SZCS63AB',
        secret_access_key: 'kISdQfc6igXQ8jvd1dpqSuJArPq60ucPHbmidkr8',
        server: 'https://obs.cn-south-1.myhuaweicloud.com'
    });
    useEffect(()=>{
        if(models.current.length)
            return;
        // map.addLayer({
        //     id: '3D_layer',
        //     type: 'custom',
        //     renderingMode: '3d',
        //     onAdd: function (map, mbxContext) {
    
        //       get3D('HUTB').then((data) => {
        //         var options = {
        //           obj: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/%E7%AE%97%E7%BD%91%E4%B8%AD%E5%BF%83.glb',
        //           type: 'gltf',
        //           scale: 1,
        //           units: 'meters',
        //           rotation: { x: 90, y: 0, z: 0 } //default rotation
        //       }
    
        //       window.tb.loadObj(options, function (model: any) {
        //           let soldier = model.setCoords([112.9163703624676, 28.22016740879758]);
        //           window.tb.add(soldier);
        //       })
        //         data.forEach((obj: any) => {
        //           var options = {
        //             obj: obj.obj,
        //             type: obj.type,
        //             scale: obj.scale,
        //             units: 'meters',
        //             rotation: { x: 90, y: 0, z: 0 } //default rotation
        //           }
        //           window.tb.loadObj(options, function (model: any) {
        //             let soldier = model.setCoords(obj.coords);
        //             soldier.setRotation({x: obj.rotation._x*180/Math.PI, y: obj.rotation._y*180/Math.PI, z: obj.rotation._z*180/Math.PI})
        //             models.current.push(soldier);
        //             window.tb.add(soldier);
        //           })
                  
        //         });
        //       }
        //       )
    
        //     },
        //     render: function (gl, matrix) {
        //       window.tb.update();
        //     }
        //   });
    
        get3D('HUTB').then((data) => {
            data.forEach((obj: any) => {
              var options = {
                obj: obj.obj,
                type: obj.type,
                scale: obj.scale,
                units: 'meters',
                rotation: { x: 90, y: 0, z: 0 } //default rotation
              }
              window.tb.loadObj(options, function (model: any) {
                let soldier = model.setCoords(obj.coords);
                soldier.setRotation({x: obj.rotation._x*180/Math.PI, y: obj.rotation._y*180/Math.PI, z: obj.rotation._z*180/Math.PI})
                models.current.push(soldier);
                window.tb.add(soldier);
              })
              
            });
          }
          )
    },[])
    useEffect(() => {
        if (icon === 1) {
            console.log("tb", window.tb.memory());
            let center = map.getCenter();
            let coord = [center.lng, center.lat];
            console.log("add")
            var options = {
                obj: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/%E6%A1%8C%E6%A4%85.glb',
                type: 'gltf',
                scale: 0.005,
                units: 'meters',
                rotation: { x: 90, y: 0, z: 0 } //default rotation
            }

            window.tb.loadObj(options, function (model: any) {
                let soldier = model.setCoords(coord);
                models.current.push(soldier);
                window.tb.add(soldier);
            })
            window.tb.update();
        }
        else if(icon === 2){
            let center = map.getCenter();
            let coord = [center.lng, center.lat];
            console.log("add")
            var options = {
                obj: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/%E5%89%8D%E5%8F%B0.glb',
                type: 'gltf',
                scale: 1,
                units: 'meters',
                rotation: { x: 90, y: 0, z: 0 } //default rotation
            }

            window.tb.loadObj(options, function (model: any) {
                let soldier = model.setCoords(coord);
                models.current.push(soldier);
                window.tb.add(soldier);
            })
            window.tb.update();
        }
        else if(icon === 3){
            
            let center = map.getCenter();
            let coord = [center.lng, center.lat];
            console.log("add")
            var options = {
                obj: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/%E4%BC%9A%E8%AE%AE%E6%A1%8C.glb',
                type: 'gltf',
                scale: 0.005,
                units: 'meters',
                rotation: { x: 90, y: 0, z: 0 } //default rotation
            }

            window.tb.loadObj(options, function (model: any) {
                let soldier = model.setCoords(coord);
                models.current.push(soldier);
                window.tb.add(soldier);
            })
            window.tb.update();
        }
        else if(icon === 4){
            
            let center = map.getCenter();
            let coord = [center.lng, center.lat];
            console.log("add")
            var options = {
                obj: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/%E5%B1%95%E5%8F%B0.glb',
                type: 'gltf',
                scale: 0.01,
                units: 'meters',
                rotation: { x: 90, y: 0, z: 0 } //default rotation
            }

            window.tb.loadObj(options, function (model: any) {
                let soldier = model.setCoords(coord);
                models.current.push(soldier);
                window.tb.add(soldier);
            })
            window.tb.update();
        }
    }, [icon])
    const save = () => {
        console.log(models.current);
        let modelJson: { coords: any; rotation: any; scale: any; type: any; obj: any; }[] = [];
        if(models.current.length){
            models.current.forEach((model)=>{
                modelJson.push({
                    coords: model.coordinates,
                    rotation: model.rotation,
                    scale: model.userData.scale,
                    type: model.userData.type,
                    obj: model.userData.obj,
                })
            })
            obsClient.putObject({
                Bucket: 'mitu',
                Key: `HUTB/3D.json`,
                Body: modelJson
            }, (err: any, result: any) => {
                if (err) {
                    console.error('Error-->' + err);
                } else {
                    console.log('Status-->' + result.CommonMsg.Status);
                }
            });
            
        }
    }
    return (
        <div>
            <Card title='图标设置' className="absolute bottom-0 w-full">
                <Space direction='vertical'>
                    <Space >
                        <Image src={desk} width={40} onClick={() => { setIcon(1); setAngle(0); setLastAngle(0) }} />
                        <Image src={desk} width={40} onClick={() => { setIcon(2); setAngle(0); setLastAngle(0) }} />
                        <Image src={desk} width={40} onClick={() => { setIcon(3); setAngle(0); setLastAngle(0) }} />
                        <Image src={desk} width={40} onClick={() => { setIcon(4); setAngle(0); setLastAngle(0) }} />
                    </Space>
                    <Radio.Group defaultValue={icon} value={icon}>
                        <Space style={{ '--gap': '2.42rem', 'marginLeft': '0.9rem' }}>
                            <Radio style={{ '--icon-size': '10px' }} value={1} />
                            <Radio style={{ '--icon-size': '10px' }} value={2} />
                            <Radio style={{ '--icon-size': '10px' }} value={3} />
                            <Radio style={{ '--icon-size': '10px' }} value={4} />
                        </Space>
                    </Radio.Group>
                </Space>
                <Button onClick={save}>保存</Button>
                <Button onClick={setCancel}>返回</Button>
            </Card>
        </div>
    )
}

export default Threebox;