import axios from 'axios';
const baseURL = 'https://mitu.obs.cn-south-1.myhuaweicloud.com';
const instance = axios.create({
  baseURL,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json'
  }
});
const instance1 = axios.create({
  baseURL: 'https://mitu.obs.cn-south-1.myhuaweicloud.com',
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export async function getMapConfig(name: string) {
  try {
    console.log('Getting mapconfig');
    const response = await instance1.get(`/${name}/config.json`);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Error getting mapconfig');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getBeacons(name: string) {
  try {
    console.log('Getting beacons');
    const response = await instance1.get(`/${name}/beacons.json`);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Error getting beacons');
    }
  } catch (error) {
    console.error(error);
    // throw error;
  }
}

export async function getFingerprintList(name: string) {
  try {
    console.log('Getting beacons');
    const response = await instance1.get(`/${name}/fingerprints/fingerprintList.json`);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Error getting beacons');
    }
  } catch (error) {
    console.error(error);
    // throw error;
  }
}

export async function getFingerprint(name: string, fingerprint: string) {
  try {
    console.log('Getting beacons');
    const response = await instance.get(`/${name}/fingerprints/${fingerprint}.json`);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Error getting beacons');
    }
  } catch (error) {
  }
}
export async function getFingerprints(name: string) {
  try {
    console.log('Getting beacons');
    const response = await instance.get(`/${name}/fingerprints/fingerprints.json`);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Error getting beacons');
    }
  } catch (error) {
  }
}

export async function get3D(name: string) {
  try {
    console.log('Getting beacons');
    const response = await instance1.get(`/${name}/3D.json`);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Error getting beacons');
    }
  } catch (error) {
    console.error(error);
    // throw error;
  }
}