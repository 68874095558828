
function inside(x1: number, y1: number, x2: number, y2: number, xk: number, yk: number): Boolean {
    // 若与 x 轴平行，只需要判断 x 的部分
    // 若与 y 轴平行，只需要判断 y 的部分
    // 若为普通线段，则都要判断
    return (x1 === x2 || (Math.min(x1, x2) <= xk && xk <= Math.max(x1, x2))) && (y1 === y2 || (Math.min(y1, y2) <= yk && yk <= Math.max(y1, y2)));
}
function update(x1:number|undefined, y1:number|undefined, xk: number,yk: number) {
    // 将一个交点与当前 ans 中的结果进行比较
    // 若更优则替换
    if (x1 === undefined || y1 === undefined || xk < x1 || (xk === x1 && yk < y1)) {
        return[xk,yk]
    }
    else{
        return[x1, y1]
    }
}

//计算两线段相交点
export function lineIntersect(node1:[number, number] , node2: [number, number],node3: [number, number], node4: [number, number]):[number, number]|undefined{
    let x1 = node1[0]
    let y1 = node1[1]
    let x2 = node2[0]
    let y2 = node2[1]
    let x3 = node3[0]
    let y3 = node3[1]
    let x4 = node4[0]
    let y4 = node4[1]
    let xk,yk;
    //判断两线段是否平行
    if((x2-x1)*(y4-y3)===(x4-x3)*(y2-y1)){
        if (inside(x1, y1, x2, y2, x3, y3)) {
           [xk,yk] =  update(xk,yk, x3, y3);
        }
        // 判断 (x4, y4) 是否在「线段」(x1, y1)~(x2, y2) 上
        if (inside(x1, y1, x2, y2, x4, y4)) {
            [xk,yk] =  update(xk,yk, x4, y4);
        }
        // 判断 (x1, y1) 是否在「线段」(x3, y3)~(x4, y4) 上
        if (inside(x3, y3, x4, y4, x1, y1)) {
            [xk,yk] =  update(xk,yk, x1, y1);
        }
        // 判断 (x2, y2) 是否在「线段」(x3, y3)~(x4, y4) 上
        if (inside(x3, y3, x4, y4, x2, y2)) {
            [xk,yk] =  update(xk,yk, x2, y2);
        }
        if(xk !== undefined && yk !== undefined)
        return[xk,yk]
     return undefined;
    }
    else{
     let t1 = (x3*(y4-y3)+y1*(x4-x3)-x1*(y4-y3)-y3*(x4-x3))/((x2-x1)*(y4-y3)-(x4-x3)*(y2-y1));
     let t2 = (x1*(y2-y1)+y3*(x2-x1)-x3*(y2-y1)-y1*(x2-x1))/((x4-x3)*(y2-y1)-(x2-x1)*(y4-y3));
     if(t1>=0 && t1<=1 && t2>=0 && t2<=1){
         return [x1+t1*(x2-x1),y1+t1*(y2-y1)];
     }
     return undefined;
    }

 }

 //计算两点距离
    export function getDistance(node1:[number, number], node2:[number, number]):number{
        let x1 = node1[0]
        let y1 = node1[1]
        let x2 = node2[0]
        let y2 = node2[1]
        return Math.sqrt(Math.pow(x1-x2,2)+Math.pow(y1-y2,2))
    }

    export function toMercator(node:[number, number]):[number, number]{
        let x = node[0] //lon
        let y = node[1]//lat 
        var earthRad = 6378137.0;
        let x1 = x * Math.PI / 180 * earthRad;
        let a = y * Math.PI / 180;
        let y1 = earthRad / 2 * Math.log((1.0 + Math.sin(a)) / (1.0 - Math.sin(a)));
        return [x1, y1];
    }

    /**
 * Convert lon/lat values to 900913 x/y.
 * (from https://github.com/mapbox/sphericalmercator)
 *
 * @private
 * @param {Array<number>} lonLat WGS84 point
 * @returns {Array<number>} Mercator [x, y] point
 */
export function convertToMercator(lonLat:[number, number]):[number, number] {
    var D2R = Math.PI / 180, 
    // 900913 properties
    A = 6378137.0, MAXEXTENT = 20037508.342789244;
    // compensate longitudes passing the 180th meridian
    // from https://github.com/proj4js/proj4js/blob/master/lib/common/adjust_lon.js
    var adjusted = Math.abs(lonLat[0]) <= 180 ? lonLat[0] : lonLat[0] - sign(lonLat[0]) * 360;
    var xy = [
        A * adjusted * D2R,
        A * Math.log(Math.tan(Math.PI * 0.25 + 0.5 * lonLat[1] * D2R)),
    ];
    // if xy value is beyond maxextent (e.g. poles), return maxextent
    if (xy[0] > MAXEXTENT)
        xy[0] = MAXEXTENT;
    if (xy[0] < -MAXEXTENT)
        xy[0] = -MAXEXTENT;
    if (xy[1] > MAXEXTENT)
        xy[1] = MAXEXTENT;
    if (xy[1] < -MAXEXTENT)
        xy[1] = -MAXEXTENT;
    return xy as [number, number];
}


/**
 * Convert 900913 x/y values to lon/lat.
 * (from https://github.com/mapbox/sphericalmercator)
 *
 * @private
 * @param {Array<number>} xy Mercator [x, y] point
 * @returns {Array<number>} WGS84 [lon, lat] point
 */
export function convertToWgs84(xy:[number, number]):[number, number] {
    // 900913 properties.
    var R2D = 180 / Math.PI;
    var A = 6378137.0;
    return [
        (xy[0] * R2D) / A,
        (Math.PI * 0.5 - 2.0 * Math.atan(Math.exp(-xy[1] / A))) * R2D,
    ];
}

function sign(x:number) {
    return x < 0 ? -1 : x > 0 ? 1 : 0;
}
    export function toWGS84(node:[number, number]):[number, number]{
        let x = node[0]
        let y = node[1]
        var earthRad = 6378137.0;
        let x1 = x / Math.PI * 180 / earthRad;
        let a = y / earthRad;
        let y1 = 180 / Math.PI * (2 * Math.atan(Math.exp(a)) - Math.PI / 2);
        return [x1, y1];
    }

    //计算P3到P1、P2组成线段上的最近点，使用墨卡托坐标系
   export function nearestPointOnLine(point1:[number, number], point2:[number, number], point3:[number, number]) {
        const vectorPoint1to2 = [point2[0] - point1[0], point2[1] - point1[1]];
        const vectorPoint1to3 = [point3[0] - point1[0], point3[1] - point1[1]];
      
        const dotProduct = vectorPoint1to2[0] * vectorPoint1to3[0] + vectorPoint1to2[1] * vectorPoint1to3[1];
        const lengthSquared = vectorPoint1to2[0] * vectorPoint1to2[0] + vectorPoint1to2[1] * vectorPoint1to2[1];
      
        const projectionFactor = dotProduct / lengthSquared;
      
        let projectionPoint = [
          point1[0] + projectionFactor * vectorPoint1to2[0],
          point1[1] + projectionFactor * vectorPoint1to2[1]
        ];

        let dis1 = getDistance(point1, point2);
        let dis2 = getDistance(point1, projectionPoint as [number, number])
        let dis3 = getDistance(point2, projectionPoint as [number, number])
        if(dis1 === dis2+dis3){
            
        }
        else{
            if(dis2 > dis3){            
                projectionPoint = point2//不在两点之间，且距离P2近
            }
            else{
                projectionPoint = point1
            }
        }

        let dis = getDistance(point3, projectionPoint as [number, number])
      
        return {
            projectionPoint,
            dis
        };
      }

      export function calculateDirection(coord1:[number, number], coord2:[number, number]) {
        const x1 = coord1[0];
        const y1 = coord1[1];
        const x2 = coord2[0];
        const y2 = coord2[1];
      
        const deltax = x2 - x1;
        const deltay = y2 - y1;
       let angle = Math.atan2(deltay, deltax)
        // 计算夹角
        let degrees = angle * (180 / Math.PI)
        if(deltax === 0){
            if(deltay > 0){
                degrees = 0
            }
            else{
                degrees = 180
            }
        }
        else if(deltay === 0){
            if(deltax > 0){
                degrees = 90
            }
            else{
                degrees = 270
            }
        }
        else if(deltax > 0 && deltay > 0){
            degrees = 90 - degrees
        }
        else if(deltax < 0 && deltay > 0){
          degrees = 450 - degrees
        }
        else if(deltax < 0 && deltay < 0){
            degrees = 90 - degrees
        }
        else if (deltax > 0 && deltay < 0){
            
             degrees = 90 - degrees
        }
        return degrees;
      }



