// import { BuildingData } from '../../features/map/components/LayerManager';
import { SET_DRAW, SET_MAP} from '../actions/mapActions';

const mapInitialState = {
  map: null,
  draw: null,
  marker: null
};

export const mapReducer = (state = mapInitialState, action:any) => {
  switch (action.type) {
    case SET_MAP:
      return { ...state, map: action.payload };
    case SET_DRAW:
      return { ...state, draw: action.payload };
    case 'SET_MARKER':
      return { ...state, marker: action.payload };
    default:
      return state;
  }
};

type MapState = 'loading' | 'error' | 'idle'| 'success';
const MapStateInitialState: MapState = 'idle';
export const mapStateReducer = (state = MapStateInitialState, action: any) => {
    switch (action.type) {
        case 'map/setMapState':
            return action.payload;
        default:
            return state;
    }
  }

export const allFloorsReducer = (state = ['1F'], action: any) =>{
  switch (action.type) {
    case 'SET_ALL_FLOORS':
      return action.payload;
    default:
      return state;
  }
}

export  const floorReducer = (state = '1F', action:any) => {
    switch (action.type) {
        case 'SET_FLOOR':
            return action.payload;
        default:
            return state;
    }
}

export const drawingReducer = (state = 'BLDComplex', action:any) => {
    switch (action.type) {
        case 'map/setDrawing':
            return action.payload;
        default:
            return state;
    }
}
const BuildingInit:any = {
  buildingID:'',
  floors:[]
}
export const buidingDataReducer = (state = BuildingInit, action:any) => {
    switch (action.type) {
        case 'setBuidingData':
            return action.payload;
        default:
            return state;
    }
}

export const beaconsReducer = (state = [], action:any) => {
    switch (action.type) {
        case 'setBeacons':
            return action.payload;
        default:
            return state;
    }
}

// export const LayerStateReducer = (state = MapStateInitialState, action: any) => {
//     switch (action.type) {
//         case 'map/setLayerState':
//             return action.payload;
//         default:
//             return state;
//     }
// }


