import { Card, Dropdown, Input, Radio, Space, Form, Button, Dialog, Switch } from "antd-mobile";
import { FC, useEffect, useRef, useState } from "react";
import ReactEcharts from 'echarts-for-react';
// import AccelerationChart from "./AccelerationChart";
import ObsClient from 'esdk-obs-browserjs'
import dayjs from "dayjs";
import { inertialNavigator } from "../../algorithmn/inertialNavigator";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
interface AccelerationData {
  x: number;
  y: number;
  z: number;
  timestamp: number;
}
const obsClient = new ObsClient({
  //推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险。
  //您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
  access_key_id: 'TWRHS7BEB4P4SZCS63AB',
  secret_access_key: 'kISdQfc6igXQ8jvd1dpqSuJArPq60ucPHbmidkr8',
  server: 'https://obs.cn-south-1.myhuaweicloud.com'
});
const DataAnalyse: FC = () => {
  const map = useSelector((state: any) => state.map.map as mapboxgl.Map);
  const marker = useSelector((state: any) => state.map.marker as mapboxgl.Marker);
  const location = useLocation();
  const [selected, setSelected] = useState<string>('');
  const [distance, setDistance] = useState<number>(0);
  const [obstructed, setObstructed] = useState<boolean>(false);
  const rssi = useRef<number[][]>([]);
  const [stop, setStop] = useState(false);
  useEffect(() => {
    let hash = location.hash
    let data = hash.substring(1);
    if (data === '' || stop)
      return;
    let urlData = JSON.parse(decodeURIComponent(data));
    let beacon = urlData
    for (const key in beacon) {
      console.log(key, selected);
      if (key === selected) {
        let mean = beacon[key].reduce((acc: any, val: any) => acc + val, 0) / beacon[key].length;
        if(!rssi.current[distance])
          rssi.current[distance] = [];
        rssi.current[distance].push(mean);
      }
    }
  }, [location])
  const onFinish = (values: any) => {
    console.log(values);
    setSelected(values.beacon);
    setDistance(values.distance);
    setObstructed(values.obstructed);
    map.on('click', (e) => {
      const coord = [e.lngLat.lng, e.lngLat.lat] as any;
      marker.setLngLat(coord);
    })
  }
  function save() {
    obsClient.putObject({
      Bucket: 'mitu',
      Key: `HUTB/${dayjs().format('YYYY-MM-DD-HH-mm-ss')}-${obstructed}.json`,
      Body: rssi.current
    }, (err: any, result: any) => {
      if (err) {
        console.error('Error-->' + err);
      } else {
        console.log('Status-->' + result.CommonMsg.Status);
      }
    });
  }

  return (
    <div className="z-40 absolute bottom-0 w-full">
      <Form layout='horizontal'
        onFinish={onFinish}
        footer={
          <Button block type='submit' color='primary' size='large'>
            提交
          </Button>
        }>
        <Form.Item label="方向" name={'direction'} rules={[{ required: true, message: '方向不能为空' }]}>
          <Input type='number' placeholder="请输入方向" />
        </Form.Item>
        <Form.Item label="距离" name={'distance'} rules={[{ required: true, message: '距离不能为空' }]}>
          <Input type="number" placeholder="请输入距离" />
        </Form.Item>
        <Form.Item label="marjor-minor" name={'beacon'} rules={[{ required: true, message: '不能为空' }]}>
          <Input type="text" placeholder="请输入marjor-minor" />
        </Form.Item>
        <Form.Item
          name='obstructed'
          label='是否有遮挡'
          childElementPosition='right'
        >
          <Switch />
        </Form.Item>
      </Form>
      <Button block type='submit' color='primary' size='large' onClick={()=>{setStop(!stop)}}>{stop ?'开始': '暂停'}</Button>
      <Button block type='submit' color='primary' size='large' onClick={save}>
        保存
      </Button>
    </div>
  )
  //   const [selected, setSelected] = useState<string>('');
  //   const acceleration = useRef<AccelerationData[]>([]);
  //   const accelerationIncludingGravity = useRef<any[]>();
  //   const rotationRate = useRef<any[]>();
  //   const [step, setStep] = useState(0);
  //   // const option = {};
  //   const [option, setOption] = useState<any>({})

  //   const handleAcc = (e: boolean) => {
  //     if (!e)
  //       return;
  //     acceleration.current = [];
  //     accelerationIncludingGravity.current = [];
  //     rotationRate.current = []
  //     window.addEventListener("devicemotion", function (event: any) {
  //       if (event.accelerationIncludingGravity && event.accelerationIncludingGravity.x !== null && event.accelerationIncludingGravity.y !== null && event.accelerationIncludingGravity.z !== null) {
  //         const data: AccelerationData = {
  //           x: event.accelerationIncludingGravity.x,
  //           y: event.accelerationIncludingGravity.y,
  //           z: event.accelerationIncludingGravity.z,
  //           timestamp: event.timeStamp / 1000,
  //         };
  //         acceleration.current?.push(data);
  //       }
  //       // console.log(event.acceleration + " m/s2");
  //       // accelerationIncludingGravity.current?.push(event.accelerationIncludingGravity);
  //     });
  //   }
  //   const save = (e: boolean)=>{
  //     if(!e) return;
  //     setSelected('save')
  //     const xAxisData = acceleration.current.map(data => data.timestamp);
  //     console.log(acceleration.current);
  //     setOption({
  //       // ... 其他基础配置项不变 ...
  //       xAxis: {
  //         type: 'category',
  //         data: xAxisData,
  //       },
  //       yAxis: [
  //         {
  //           type: 'value',
  //           name: '加速度',
  //           axisLabel: {
  //             formatter: '{value} m/s^2',
  //           },
  //         },
  //       ],
  //       series: [
  //         {
  //           name: 'X轴加速度',
  //           type: 'line',
  //           data: acceleration.current.map(data => data.x),
  //           yAxisIndex: 0,
  //         },
  //         {
  //           name: 'Y轴加速度',
  //           type: 'line',
  //           data: acceleration.current.map(data => data.y),
  //           yAxisIndex: 0,
  //         },
  //         {
  //           name: 'Z轴加速度',
  //           type: 'line',
  //           data: acceleration.current.map(data => data.z),
  //           yAxisIndex: 0,
  //         },
  //       ],
  //     })

  //     const obsClient = new ObsClient({
  //       //推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险。
  //       //您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
  //       access_key_id: 'TWRHS7BEB4P4SZCS63AB',
  //       secret_access_key: 'kISdQfc6igXQ8jvd1dpqSuJArPq60ucPHbmidkr8',
  //       server: 'https://obs.cn-south-1.myhuaweicloud.com'
  //   });
  //   const now = dayjs().format('YYYYMMDDHHmmss');
  //   obsClient.putObject({
  //     Bucket: 'mitu',
  //     Key: `HUTB/InertialSensors/${now}.json`,
  //     Body: acceleration.current
  // }, (err: any, result: any) => {
  //     if (err) {
  //         console.error('Error-->' + err);
  //     } else {
  //         console.log('Status-->' + result.CommonMsg.Status);
  //     }
  // });
  //   }

  //   const exit = (e: boolean)=>{
  //     if(!e) return;
  //     setSelected('exit')
  //   }
  //   const pedometer = (e: boolean)=>{
  //     if(!e) return;
  //     setSelected('pedometer')
  //     const inertialnavigator  = new inertialNavigator();
  //     inertialnavigator.start();
  //     inertialnavigator.on('step', (step: number) => {
  //       setStep(inertialnavigator.getStep());
  //     })
  //   }
  //   return (
  //     <div>
  //       <Dropdown className="z-50 absolute top-0 w-full">
  //         <Dropdown.Item key='sorter' title='分析'>
  //           <div style={{ padding: 12 }}>
  //             <Radio.Group defaultValue='default'>
  //               <Space direction='vertical' block>
  //                 <Radio block value='acceleration' onChange={(e)=>{if(e){
  //                   setSelected('acc')
  //                   handleAcc(e);
  //                 }}}>
  //                   加速度
  //                 </Radio>
  //                 <Radio block value='gyroscope'>
  //                   陀螺仪
  //                 </Radio>
  //                 <Radio block value='pedometer' onChange={pedometer}>
  //                   计步器
  //                 </Radio>
  //                 <Radio block value='save' onChange={save}>
  //                   保存
  //                 </Radio>
  //                 <Radio block value='exit' onChange={exit}>
  //                   退出
  //                 </Radio>
  //               </Space>
  //             </Radio.Group>
  //           </div>
  //         </Dropdown.Item>
  //       </Dropdown>
  //       {
  //         selected === 'save' && <ReactEcharts
  //       className="z-50 absolute top-0 w-full"
  //           option={option}
  //           notMerge={true}
  //           lazyUpdate={true}
  //           theme={"light"}
  //           // onEvents={{ 'ready': onChartReady }}
  //           style={{ height: '400px', width: '100%' }}
  //         />

  //         }
  //         {
  //           selected === 'pedometer' && <Card className="z-50 absolute bottom-0 w-full" title='传感器信息'>
  //           {`步数：${step}`}
  //       </Card>
  //         }
  //     </div>
  //   )
}
export default DataAnalyse;