import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { coord } from "../../../utils/type";
import { Card } from "antd-mobile";
import { inertialNavigator } from "../../algorithmn/inertialNavigator";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import ObsClient from 'esdk-obs-browserjs'
import dayjs from "dayjs";
import { getBeacons, getFingerprintList } from "../../../utils/request";
import mapboxgl from "mapbox-gl";
export interface props {
    setCancel: () => void;
}
const Fingerprint: FC<props> = ({ setCancel }) => {
    const map = useSelector((state: any) => state.map.map as mapboxgl.Map)
    const [start, setStart] = useState<coord | undefined>(undefined)
    const [end, setEnd] = useState<coord | undefined>(undefined)
    const [isBegin, setBegin] = useState(false);
    const step = useRef(new inertialNavigator());
    const location = useLocation();
    const beacons = useRef<any[]>([]);
    const [deployed, setDeployed] = useState<GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined>(undefined)
    const fingerprints = useRef<Map<string, number[]>[]>([])
    const startMarker = useRef<mapboxgl.Marker | null>(null);
    const endMarker = useRef<mapboxgl.Marker | null>(null);
    const obsClient = new ObsClient({
        //推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险。
        //您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
        access_key_id: 'TWRHS7BEB4P4SZCS63AB',
        secret_access_key: 'kISdQfc6igXQ8jvd1dpqSuJArPq60ucPHbmidkr8',
        server: 'https://obs.cn-south-1.myhuaweicloud.com'
    });

    useEffect(() => {
        getBeacons('HUTB').then(res => {
            console.log(res);
            if (res) {
                setDeployed(res)
            }
        })
    }, [])
    useEffect(() => {
        let hash = location.hash
        let data = hash.substring(1);
        if (data === '')
            return;
        const beacon = JSON.parse(decodeURIComponent(data))
        if (isBegin) {
            // console.log('beacon', beacon)
            let features = deployed?.features
            if (!features)
                return;
            let filteredBeacon: { [key: string]: number } = {};
            for (let key in beacon) {
                for (let i = 0; i < features.length; i++) {
                    if (features[i].properties?.majorminor === key) {
                        filteredBeacon[key] = beacon[key]
                        break;
                    }
                }
            }

            if (Object.keys(filteredBeacon).length !== 0) {
                filteredBeacon['timestamp'] = beacon['timestamp']
                beacons.current.push(filteredBeacon)
            }

        }
        // setBeacons(beacons.push(beacon));
    }, [location])

    useEffect(() => {
        if (!map)
            return;
        map.on('click', selectedStart)
        step.current.start()
        console.log('start')
        let el = document.createElement('div')
        el.className = 'marker'
        el.style.backgroundImage = `url(https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/img/start.png)`
        el.style.width = '32px'
        el.style.height = '32px'
        el.style.backgroundSize = '100%'
        startMarker.current = new mapboxgl.Marker(el, { anchor: 'bottom' })
            .setLngLat([0, 0])
            .addTo(map)
        let ele = document.createElement('div')
        ele.className = 'marker'
        ele.style.backgroundImage = `url(https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/img/end.png)`
        ele.style.width = '32px'
        ele.style.height = '32px'
        endMarker.current = new mapboxgl.Marker(ele, { anchor: 'bottom' })
            .setLngLat([0, 0])
            .addTo(map)
    }, [map])
    const selectedStart = (e: any) => {
        const coord = [e.lngLat.lng, e.lngLat.lat]
        setStart(coord as any)

        if (startMarker.current)
            startMarker.current
                .setLngLat(coord as any)
        map.on('click', selectedEnd)
        map.off('click', selectedStart)

    }
    const selectedEnd = (e: any) => {
        const coord = [e.lngLat.lng, e.lngLat.lat]
        setEnd(coord as any)

        if (endMarker.current)
            endMarker.current
                .setLngLat(coord as any)
        map.off('click', selectedEnd)
    }
    useEffect(() => {
        if(!start){
            map.on('click', selectedStart);
        }
        if (!start || !end)
            return;
        (map.getSource('fingerprintLine') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [
                ...(map.getSource('fingerprintLine') as any)._data.features,
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: [start, end],
                    },
                    properties: {
                        'time': dayjs().format('YYYYMMDDHHmmss'),
                    },
                }],
        });
        // (map.getSource('fingerprint') as mapboxgl.GeoJSONSource).setData({
        //     type: 'FeatureCollection',
        //     features: [
        //         ...(map.getSource('fingerprint') as any)._data.features,
        //         {
        //             "type": "geojson",
        //             "data": {
        //                 "type": "Feature",
        //                 "properties": {},
        //                 "geometry": {
        //                     "type": "LineString",
        //                     "coordinates": [start, end]
        //                 }
        //             }
        //         }],
        // });

        // map.addLayer({
        //     "id": "route",
        //     "type": "line",
        //     "source": {
        //         "type": "geojson",
        //         "data": {
        //             "type": "Feature",
        //             "properties": {},
        //             "geometry": {
        //                 "type": "LineString",
        //                 "coordinates": [start, end]
        //             }
        //         }
        //     },
        //     "paint": {
        //         "line-color": "#3888fa",
        //         "line-width": 4
        //     }
        // })
    }, [start, end])
    const Begin = () => {
        if (!isBegin) {
            step.current.setStep(0);
        }
        else {
            const stepTime = step.current.getStepTime();
            const now = dayjs().format('YYYYMMDDHHmmss');
            if (stepTime.length > 0 && beacons.current.length > 0) {
                obsClient.putObject({
                    Bucket: 'mitu',
                    Key: `HUTB/fingerprints/${now}.json`,
                    Body: {
                        "step": stepTime,
                        "beacons": beacons.current,
                        "start": start,
                        "end": end,
                    }
                }, (err: any, result: any) => {
                    if (err) {
                        console.error('Error-->' + err);
                    } else {
                        console.log('Status-->' + result.CommonMsg.Status);
                    }
                });
                getFingerprintList("HUTB").then(res => {
                    obsClient.putObject({
                        Bucket: 'mitu',
                        Key: `HUTB/fingerprints/fingerprintList.json`,
                        Body: [...res, now]
                    }, (err: any, result: any) => {
                        if (err) {
                            console.error('Error-->' + err);
                        } else {
                            console.log('Status-->' + result.CommonMsg.Status);
                        }
                    });
                });

            }
            // setBeacons([])
            beacons.current = []
        }
        setBegin(!isBegin)
    }
    const clear = () => {
        setStart(undefined)
        setEnd(undefined)
        beacons.current = [];
        if (startMarker.current) {
            startMarker.current.setLngLat([0, 0]);
        }
        if (endMarker.current) {
            endMarker.current.setLngLat([0, 0]);
        }
        // 获取当前数据
        const currentData = (map.getSource('fingerprintLine') as any)._data;
        const features = currentData.features;

        // 创建一个新的 GeoJSON 对象，包含除最后一个外的所有 features
        const updatedData = {
            type: 'FeatureCollection',
            features: features.slice(0, -1)
        };

        // 使用 setData 方法更新数据源
        (map.getSource('fingerprintLine') as mapboxgl.GeoJSONSource).setData(updatedData as any);

    }
    return (
        <>
            {
                !start && <div className="z-40 absolute bottom-0 w-full text-center bg-white h-10 flex items-center justify-center">
                    请点击地图选择起点
                </div>
            }
            {
                !end && start && <div className="z-40 absolute bottom-0 w-full text-center bg-white h-10 flex items-center justify-center">
                    请点击地图选择终点
                </div>

            }
            {
                start && end && <div className="z-40 absolute bottom-0 w-full text-center bg-white">
                    <Card title='传感器信息'>
                        {`步数：${step.current.getStep()}`}
                    </Card>
                    <Button className="w-1/3" onClick={Begin} >{isBegin ? '完成' : '开始'}</Button>
                    <Button className="w-1/3" onClick={clear}>清除路径</Button>
                    <Button className="w-1/3" onClick={() => {clear(); setCancel()}}>返回</Button>
                </div>
            }
        </>
    )
}
export default Fingerprint;