// import { BuildingData } from "../../features/map/components/LayerManager";

// Action Types
export const SET_MAP = 'SET_MAP';
export const SET_MAP_STATE = 'SET_MAP_STATE';
export const SET_ALL_FLOORS = 'SET_ALL_FLOORS';
export const SET_FLOOR = 'SET_FLOOR';
export const SET_DRAW = 'SET_DRAW';
// Action Creators
export const setMap = (map: any) => ({
  type: SET_MAP,
  payload: map,
});
export const setDraw = (draw: any) => ({
  type: SET_DRAW,
  payload: draw,
});
export const setMarker = (marker: any) => ({
  type: 'SET_MARKER',
  payload: marker,
});

type MapState = 'loading' | 'error' | 'idle'| 'success';
export const setMapState = (state: MapState) => ({
  type: SET_MAP_STATE,
  payload: state,
});

export const setAllFloors = (floors: any) => ({
  type: SET_ALL_FLOORS,
  payload: floors,
});
export const setFloor = (floor: any) => ({
  type: SET_FLOOR,
  payload: floor,
});
type drawingType = 'BLDComplex' | 'buiding' | 'floor' | 'wall' | 'cellSpace' | 'POI' | 'furniture' | 'road' | 'gateway' | 'stair';
export const setDrawing = (drawing: drawingType)=> ({
  type: 'map/setDrawing',
  payload: drawing,
});

export const setBuidingData = (buidingData: any) => ({
  type: 'setBuidingData',
  payload: buidingData,
});

export const setBeacons = (beacons: any) => ({
  type: 'setBeacons',
  payload: beacons,
});



