import { Button, Card, Space } from "antd-mobile";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { KNN } from "../../locator/KNN";
import { getBeacons, getFingerprints } from "../../../utils/request";
import Locator from "../../locator/locator";
import { re } from "mathjs";

export interface props {
    setCancel: () => void;
}
let knn: KNN;
let coord: [number, number] = [0, 0];
const locator = new Locator();
const Test: FC<props> = ({ setCancel }) => {
    const map = useSelector((state: any) => state.map.map as mapboxgl.Map);
    const marker = useSelector((state: any) => state.map.marker as mapboxgl.Marker);
    const location = useLocation();
    // const [state, setState] = useState<string>()
    const state = useRef<string>()
    const [err, setErr] = useState<string | number>('err');
    const markerRef = useRef<mapboxgl.Marker>();

    const duration = 300; // 平滑移动的总时长（单位：毫秒）

    let lastUpdateTimestamp: number|undefined;

    function animate(currentTime: number) {
        if (!lastUpdateTimestamp) {
            lastUpdateTimestamp = currentTime;
        }
        if(!markerRef.current) return;
        const elapsedTime = currentTime - lastUpdateTimestamp;
        const progress = Math.min(elapsedTime / duration, 1); // 限制在[0, 1]区间

        // 根据动画进度计算当前位置
        const currentLngLat = {
            lng: coord[0] + (markerRef.current.getLngLat().lng - coord[0]) * (1 - progress),
            lat: coord[1] + (markerRef.current.getLngLat().lat - coord[1]) * (1 - progress),
        };

        // 更新Marker位置
        markerRef.current?.setLngLat(currentLngLat);

        // 如果动画未完成，请求下一帧
        if (progress < 1) {
            window.requestAnimationFrame(animate);
        }
    }
    function handleLocate(data: any) {
            coord = data;
            if (markerRef.current) {
              lastUpdateTimestamp = undefined;
              window.requestAnimationFrame(animate);
            }
    }
    function handleHeading(e:any){
        markerRef.current?.setRotation((e-map.getBearing())%360);
    }
    useEffect(() => {
        markerRef.current = marker;
        getFingerprints('HUTB').then((fingerprints) => {
            // knn = new KNN(res)
            getBeacons("HUTB").then((beacons) => {
                knn = new KNN(fingerprints, beacons)
            })
        })
        locator.on("coord", handleLocate);
        locator.on('heading1',handleHeading)
        // locator.on("beaconPrint", handleBeaconPrint)
        return () => {
            locator.off("coord", handleLocate);
            locator.off("heading1", handleHeading)
        }
    }, [])

    function startTest(type: string) {
        state.current = type;

    }

    function handleBeaconPrint(data: { key: string, value: number, coord: [number, number] }[]){
        (map.getSource('beaconPrint') as mapboxgl.GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: data.map(item => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: item.coord
                    },
                    properties: {
                        key: item.key,
                        value: item.value
                    }
                }))
        });
    }
    useEffect(() => {
        if (!knn || !map || !(state.current === 'knn' || state.current === 'pdr+knn' || state.current === 'pdr'))
            return;
        let hash = location.hash
        let data = hash.substring(1);
        if (data === '')
            return;
        if (state.current === 'knn') {
            let urlData = JSON.parse(decodeURIComponent(data));
            knn.setHeading(urlData['compassDirection']);
            coord = knn.start(decodeURIComponent(data));

        }
        else if (state.current === 'pdr') {
            map.on('click', setCoord);
            let urlData = JSON.parse(decodeURIComponent(data));
            locator.start();
            locator.emit('heading', urlData['compassDirection']);
            // locator.emit('heading', 0);
            locator.on("coord", (data) => coord = data);
        }
        else if (state.current === 'pdr+knn') {
            let urlData = JSON.parse(decodeURIComponent(data));
            locator.start();
            locator.emit('heading', urlData['compassDirection']);
            locator.emit('knn', decodeURIComponent(data));
            locator.on("coord", (data) => coord = data);
        }
        if (coord[0] === 0 && coord[1] === 0)
            return;
        // setErr(`误差：${knn.getErr()}, 实时指纹：${JSON.stringify(knn.getKey())}, 匹配指纹： ${JSON.stringify(knn.getDistances())}`);
        (map.getSource('beacons') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [],
                    },
                    properties: {

                    }
                }],
        });
    }, [location, state.current]);
    const setCoord = (e: any) => {

        const coord = [e.lngLat.lng, e.lngLat.lat] as any;
        marker.setLngLat(coord);
        locator.setCoord(coord);
        // locator.setCoord(coord);
        // (map.getSource('beacons') as mapboxgl.GeoJSONSource).setData({
        //     type: 'FeatureCollection',
        //     features: [
        //         {
        //             type: 'Feature',
        //             geometry: {
        //                 type: 'Point',
        //                 coordinates: coord,
        //             },
        //             properties: {

        //             }
        //         }],
        // });
    }
    // function handlePDR(){
    //     map.on('click', setCoord);
    //     // let urlData = JSON.parse(decodeURIComponent(data));
    //     locator.start();
    //     locator.emit('heading',0);
    //     locator.on("coord", (data) => coord = data);
    // }
    const Cancel = () => {
        // setSelected(undefined);
        (map.getSource('beacons') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [],
        })
        setCancel();
    }
    return (
        <div className="z-40 absolute bottom-0 w-full">
            <Card title='定位测试'>
                <Space className="w-full">
                    <Button color='primary' onClick={() => { state.current = 'knn' }}>KNN</Button>
                    <Button color='primary' onClick={() => { state.current = 'pdr'}}>PDR</Button>
                    <Button color='primary' onClick={() => { state.current = 'pdr+knn' }}>PDR+KNN</Button>
                    <Button color='primary' onClick={() => { state.current = ''; map.off('click', setCoord); }}>取消</Button>
                    <Button onClick={Cancel}>返回</Button>
                </Space>
            </Card>
        </div>
    )
}
export default Test;


