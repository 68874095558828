import EventEmitter from "eventemitter3";
import { inertialNavigator } from "./inertialNavigator";
import { KNN } from "./KNN";
import { getBeacons, getFingerprints } from "../../utils/request";
import { convertToMercator, convertToWgs84, getDistance, toMercator, toWGS84 } from "../../utils/agos";
import { GPSLocastor } from "./GPSLocator";
import * as math from 'mathjs';
import { KnnLocator } from "./KnnLocator";
class Locator extends EventEmitter {
    PDR: inertialNavigator;
    knn: KNN;
    knnLocator: KnnLocator;
    gps: GPSLocastor;
    beaconPrint: { key: string, value: number, coord: [number, number] }[] = [];
    orientation: {
        alpha: number,
        beta: number,
        gamma: number
    };
    coord: [number, number] = [0, 0];
    BLCoords: [number, number][] = [];
    S = 0.6;
    stepLength: number[] = [];
    BLTime: number = 0; //蓝牙定位时间搓
    PDRTime: number = 0; //蓝牙定位时间搓
    isStart = false;
    alpha = -1;
    isSetAlpha = false;
    constructor() {
        super();
        this.PDR = new inertialNavigator();
        this.knn = new KNN();
        this.knnLocator = new KnnLocator();
        this.gps = new GPSLocastor();

        getFingerprints('HUTB').then((fingerprints) => {
            // knn = new KNN(res)
            getBeacons("HUTB").then((beacons) => {
                this.knn.set(fingerprints, beacons);
                this.knnLocator.set(fingerprints, beacons);
            })
        })
        this.orientation = {
            alpha: -1000,
            beta: 0,
            gamma: 0
        };
    }
    start() {
        if (this.isStart)
            return;
        this.isStart = true;
        // this.gps.start();
        this.PDR.start();
        // this.gps.on("coord", (e: GeolocationCoordinates) => {
        //     if (e.heading)
        //         this.orientation.alpha = e.heading;
        // })
        // this.PDR.on("orientation", (e) => {
        //     this.orientation = {
        //         alpha: e.alpha,
        //         beta: e.beta,
        //         gamma: e.gamma
        //     }
        // })

        this.PDR.on("orientation", (e)=>{
            // let angleDiff = 0;
            // if(this.alpha === -1){
            //     this.alpha = e.alpha;
            // }
            // if(this.isSetAlpha === false){
            //     this.orientation.alpha = e.alpha;
            //     return;
            // }
            // // if(Math.abs(this.orientation.alpha - e.alpha) < 10 || Math.abs(this.orientation.alpha - e.alpha) > 350){
            // //     this.orientation.alpha = e.alpha;
            // //     return;
            // // }
            // angleDiff = (this.alpha - e.alpha + 360)%360;
            // this.orientation.alpha += angleDiff;
            // this.orientation.alpha %= 360;
            // this.alpha = e.alpha;
            let heading = this.orientation.alpha;
            if (Math.abs(this.orientation.alpha - 90) <= 10) {
                heading = 90;
            }
            else if (Math.abs(this.orientation.alpha - 180) <= 10) {
                heading = 180;
            }
            else if (Math.abs(this.orientation.alpha - 270) <= 10) {
                heading = 270;
            }
            else if (360 - this.orientation.alpha <= 10 || this.orientation.alpha <= 10) {
                heading = 0;
            }
            this.emit("heading1", heading);
        })
        this.PDR.on("step", (e) => {
            this.PDRTime = Date.now();
            let coord = convertToMercator(this.coord);
            this.BLCoords = [];
            let heading = this.orientation.alpha;
            if (Math.abs(this.orientation.alpha - 90) <= 15) {
                heading = 90;
            }
            else if (Math.abs(this.orientation.alpha - 180) <= 15) {
                heading = 180;
            }
            else if (Math.abs(this.orientation.alpha - 270) <= 15) {
                heading = 270;
            }
            else if (360 - this.orientation.alpha <= 15 || this.orientation.alpha <= 15) {
                heading = 0;
            }
            coord[0] = coord[0] + this.S * Math.sin(heading * Math.PI / 180);
            coord[1] = coord[1] + this.S * Math.cos(heading * Math.PI / 180);
            this.coord = convertToWgs84(coord);
            this.emit("coord", this.coord);
            console.log(coord, heading);
        })
        this.PDR.on("stepLength", (e) => {
            this.stepLength.push(e);
            if (this.stepLength.length > 4) {
                this.stepLength.shift();
            }
            this.S = this.stepLength.reduce((a, b) => a + b) / this.stepLength.length;
        })
        this.on('knn', (data) => {
            // this.knn.setHeading(this.orientation.alpha);
            // let coord = this.knn.start(data);
            // this.knnLocator.setHeading(this.orientation.alpha);
            let coord = this.knnLocator.start(data);
            this.beaconPrint = this.knnLocator.getBeaconPrint();
            this.emit("beaconPrint", this.beaconPrint);
            // let err = this.knn.getErr();
            if (coord[0] !== 0 && coord[1] !== 0) {
                this.BLCoords.push(coord);
                
                // 初始化 x 和 y 的总和
                let sumX = 0;
                let sumY = 0;

                // 遍历 BLCoords，累加 x 和 y
                for (const [x, y] of this.BLCoords) {
                    sumX += x;
                    sumY += y;
                }

                // 计算 x 和 y 平均值
                const averageX = sumX / this.BLCoords.length;
                const averageY = sumY / this.BLCoords.length;

                // 现在，averageX 和 averageY 分别是所有坐标点 x 和 y 的平均值
                // let dist = getDistance(convertToMercator(this.coord), convertToMercator(coord));
                // if( dist< 3 || dist > 50){
                if(this.BLTime === 0){
                    this.BLTime = Date.now();
                    this.coord = [averageX, averageY];
                    this.emit("coord", this.coord);
                    console.log(this.coord);
                }
                else if(Date.now() - this.PDRTime > 1500){
                    this.coord = [averageX*0.5 + this.coord[0] * 0.5, averageY*0.5 + this.coord[1] * 0.5];
                    this.emit("coord", this.coord);
                    console.log(this.coord);
                }
                else{
                    this.coord = [this.coord[0] * 0.95 + averageX * 0.05, this.coord[1] * 0.95 + averageY * 0.05]
                }
                // }

            }
        })
        this.on('heading', (e) => {
            this.isSetAlpha = true;
            this.orientation.alpha = e;
            this.knnLocator.setHeading(e)
        })
    }
    removeOutliers(coords: [number, number][]): [number, number][] {
        if (coords.length === 0) return [];
    
        // 分别计算x和y坐标的平均值和标准差
        const xValues = coords.map(point => point[0]);
        const yValues = coords.map(point => point[1]);
    
        const xMean = math.mean(xValues);
        const yMean = math.mean(yValues);
        const xStdDev = math.std(xValues) as any;
        const yStdDev = math.std(yValues) as any;
    
        // 定义一个阈值，例如Z-score大于3或小于-3的点视为离群点
        const threshold = 3;
    
        // 过滤掉离群点
        const filteredCoords = coords.filter(point => {
            const xZScore = (point[0] - xMean) / xStdDev;
            const yZScore = (point[1] - yMean) / yStdDev;
            return Math.abs(xZScore) <= threshold && Math.abs(yZScore) <= threshold;
        });
    
        return filteredCoords;
    }

    setCoord(coord: [number, number]) {
        this.coord = coord;

    }

    getHeading(){
        return this.orientation.alpha;
    }
}
export default Locator;