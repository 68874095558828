import React, { FC, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useSelector, useDispatch } from 'react-redux';
import { setAllFloors, setBeacons, setDraw, setMap, setMapState, setMarker } from '../../redux/actions/mapActions';
import './Map.css'
import RemoveWhiteBackground from './components/Ref';
import FloorSelector from './components/FloorSelector/FloorSelector';
import { Threebox } from 'threebox-plugin'
import { RGB_PVRTC_2BPPV1_Format } from 'three';
import PopOver from './components/popOver';
import BLEScan from './components/BLEScan';
import { useLocation } from 'react-router-dom';
import Fingerprint from './components/fingerprint';
import { get3D, getBeacons, getFingerprints, getMapConfig } from '../../utils/request';
import { KNN } from '../locator/KNN';
import myLocationImg from '../../assets/myLocation.svg'
// import { Threebox, } from 'threebox';
declare global {
  interface Window {
    tb?: any;
  }
}

function calculateAverage(arr: any) {
  const sum = arr.reduce((acc: any, val: any) => acc + val, 0);
  return sum / arr.length;
}

const MapboxComponent: FC = () => {
  const location = useLocation()
  const mapContainer = useRef(null); //地图容器
  const dispatch = useDispatch();
  const Object3D = useRef(null);
  const mapRef = useRef<mapboxgl.Map>();
  const accessToken = 'pk.eyJ1Ijoia29taWtlciIsImEiOiJjbGd0NTVoYnIwbjlqM25xaWtmdXZycmliIn0.D-27so-NyUbdi6jqWCRZZw'; //地图token
  //监听location，获取蓝牙数据
  useEffect(() => {
    getMapConfig('HUTB').then(res => {
      dispatch(setAllFloors(res.building[0].floors))
    })

    const map = new mapboxgl.Map({
      container: mapContainer.current as any,
      accessToken: accessToken,
      style: {
        version: 8,
        sources: {},
        layers: [],
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      },
      // style: {
      //   "version": 8,
      //   "sources": {
      //     "raster-tiles": {
      //       "type": "raster",
      //       'tiles': [
      //         // wprd0{1-4} 
      //         // scl=1&style=7 为矢量图（含路网和注记）
      //         // scl=2&style=7 为矢量图（含路网但不含注记）
      //         // scl=1&style=6 为影像底图（不含路网，不含注记）
      //         // scl=2&style=6 为影像底图（不含路网、不含注记）
      //         // scl=1&style=8 为影像路图（含路网，含注记）
      //         // scl=2&style=8 为影像路网（含路网，不含注记）
      //         "http://wprd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7"
      //       ],
      //       "tileSize": 256
      //     }
      //   },
      //   "layers": [{
      //     "id": "simple-tiles",
      //     "type": "raster",
      //     "source": "raster-tiles",
      //     "minzoom": 0,
      //     "maxzoom": 20
      //   }]
      // },
      center: [112.9163703624676, 28.22016740879758],
      zoom: 18,
      pitch: 20,
      bearing: 0,
      antialias: true,
      renderWorldCopies: false,
    }); // 地图实例
    mapRef.current = map;
    // eslint-disable-next-line no-undef
    const tb = (window.tb = new Threebox(
      map,
      map.getCanvas().getContext('webgl'),
      {
        defaultLights: true,
        enableSelectingObjects: true,
        enableTooltips: false,
        enableDraggingObjects: true,
        enableRotatingObjects: true,
        enableSelectingFeatures: true,
      }
    ));

    function onObjectMouseOver(e: any) {
      console.log(e);
    }


    map.on('load', () => {
      // 创建Marker实例并添加到地图
      let el = document.createElement('div')
      el.className = 'marker'
      el.style.backgroundImage = `url(${myLocationImg})`
      el.style.width = '25px'
      el.style.height = '25px'
      el.style.backgroundSize = '100%'
      const marker = new mapboxgl.Marker(el)
        .setLngLat([0, 0])
        .addTo(map);

      map.addSource('center', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.addSource('beacons', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.addSource('fingerprintLine', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      })
      map.addLayer({
        "id": "fingerprintLine",
        "type": "line",
        "source": 'fingerprintLine',
        "paint": {
          "line-color": "#3888fa",
          "line-width": 4
        }
      })
      map.addSource('fingerprintPoint', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      })
      map.addLayer({
        "id": "fingerprintPoint",
        "type": 'circle',
        "source": 'fingerprintPoint',
        "paint": {
          'circle-radius': 4,
          'circle-color': '#3888fa'
        }
      })
      getBeacons('HUTB').then(res => {
        console.log(res);
        if (res)
          (map.getSource('beacons') as any).setData(res);
      })
      map.addLayer({
        id: 'beacons',
        source: 'beacons',
        type: 'circle',
        paint: {
          'circle-radius': 5,
          'circle-color': '#ff0000',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000'
        }
      });


      map.addSource('test', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.addSource('test1', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      
      map.addLayer({
        id: 'test',
        source: 'test',
        type: 'circle',
        paint: {
          'circle-radius': 5,
          'circle-color': '#ff0000',
          // 'circle-stroke-width': 2,
          'circle-stroke-color': '#000000'
        }
      });

      map.addLayer({
        id: 'test1',
        source: 'test1',
        type: 'circle',
        paint: {
          'circle-radius': 5,
          'circle-color': '#00ff00',
          // 'circle-stroke-width': 2,
          'circle-stroke-color': '#000000'
        }
      });
      //https://mitu.obs.cn-south-1.myhuaweicloud.com/geojson/%E5%B1%8F%E5%B9%95%E6%88%AA%E5%9B%BE%202024-01-31%20103442.png
      map.addSource('buildings', {
        type: 'geojson',
        data: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/1F/wall.geojson' // 替换为你的在线GeoJSON文件URL
      });

      // 添加fill-extrusion图层以显示立体建筑（假设数据中有高度信息）


      map.addSource('space', {
        type: 'geojson',
        data: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/1F/SPACECELL.geojson' // 替换为你的在线GeoJSON文件URL
      });
      // map.loadImage(
      //   'https://api.komiker.xyz/HUTB/img/素色瓷砖.jpg',
      //   (err, image:any) => {
      //   if (err) throw err;
      //   map.addImage('pattern', image);

      //   }
      //   );
      map.addLayer({
        id: 'space',
        source: 'space',
        type: 'fill',
        filter: ['==', '$type', 'Polygon'],
        paint: {
          'fill-color': ['get', 'color'],
          // 'fill-extrusion-height': 0,
          // 'fill-extrusion-base': 0,
          'fill-opacity': 0.75,
          // 'fill-extrusion-pattern': 'pattern'
        },
        layout: {
          'fill-sort-key': ['get', 'level']
        }
      });
      map.addLayer({
        id: '3d-buildings',
        source: 'buildings',
        type: 'fill-extrusion',
        filter: ['==', '$type', 'Polygon'],
        paint: {
          'fill-extrusion-color': '#f7fafd',
          'fill-extrusion-height': 3,
          'fill-extrusion-base': 0,
          'fill-extrusion-opacity': 0.6
        }
      });
      map.addLayer({
        id: "label",
        type: 'symbol',
        filter: ['==', '$type', 'Polygon'],
        source: "space",
        layout: {
          'text-field': ['get', 'name'],
          'text-size': 12,
          // visibility: 'none'
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': 'rgba(255, 255, 255, 0.8)',
          'text-halo-width': 2
        }
      })
      map.addLayer({
        id: 'center',
        source: 'center',
        type: 'circle',
        paint: {
          'circle-radius': 5,
          'circle-color': '#ff0000',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000'
        }
      });

      map.addSource("5F", {
        type: "geojson",
        data: "https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/kejilou/5F/spacecell.geojson"
      })
      map.addLayer({
        id: "5F",
        type: 'fill-extrusion',
        filter: ['==', '$type', 'Polygon'],
        source: "5F",
        layout: {
          // 'fill-sort-key': ['get', 'level']
          // 'visibility': 'none',

        },
        paint: {
          'fill-extrusion-color': ['get', 'color'],
          'fill-extrusion-height': ['to-number', ['get', 'height']],
          'fill-extrusion-opacity': 0.6,
        }
      })
      map.addLayer({
        id: "5F_label",
        type: 'symbol',
        filter: ['==', '$type', 'Polygon'],
        source: "5F",
        layout: {
          'text-field': ['get', 'title'],
          'text-size': 12,
          // visibility: 'none'
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': 'rgba(255, 255, 255, 0.8)',
          'text-halo-width': 2
        }
      })
         // 添加数据源
    map.addSource('beaconPrint', {
      type: 'geojson',
      data: {
          type: 'FeatureCollection',
          features: []
      }
  });

  // 添加符号图层
  map.addLayer({
      id: 'beaconPrint',
      type: 'symbol',
      source: 'beaconPrint',
      layout: {
          'icon-image': 'circle-15',
          'text-field': '{key}: {value}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 1.25],
          'text-anchor': 'top'
      }
  });

      map.addLayer({
        id: '3D_layer',
        type: 'custom',
        renderingMode: '3d',
        onAdd: function (map, mbxContext) {
          get3D('HUTB').then((data) => {
            var options = {
              obj: 'https://mitu.obs.cn-south-1.myhuaweicloud.com/HUTB/xiangjianglou/%E7%AE%97%E7%BD%91%E4%B8%AD%E5%BF%83.glb',
              type: 'gltf',
              scale: 1,
              units: 'meters',
              rotation: { x: 90, y: 0, z: 0 } //default rotation
          }

          window.tb.loadObj(options, function (model: any) {
              let soldier = model.setCoords([112.9163703624676, 28.22016740879758]);
              window.tb.add(soldier);
          })
            data.forEach((obj: any) => {
              var options = {
                obj: obj.obj,
                type: obj.type,
                scale: obj.scale,
                units: 'meters',
                rotation: { x: 90, y: 0, z: 0 } //default rotation
              }
              window.tb.loadObj(options, function (model: any) {
                let soldier = model.setCoords(obj.coords);
                soldier.setRotation({x: obj.rotation._x*180/Math.PI, y: obj.rotation._y*180/Math.PI, z: obj.rotation._z*180/Math.PI})
                // models.current.push(soldier);
                window.tb.add(soldier);
              })

            });
          }
          )

        },
        render: function (gl, matrix) {
          window.tb.update();
        }
      });


      // map.addSource('4m',{
      //   type: "geojson",
      //   data: "https://api.komiker.xyz/HUTB/xiangjianglou/1F/4m.geojson"
      // })
      // map.addLayer({
      //   id: "4m",
      //   type: 'line',
      //   // filter: ['==', '$type', 'Polygon'],
      //   source: "4m",
      //   layout: {
      //       // 'fill-sort-key': ['get', 'level']
      //       // 'visibility': 'none',
      // }})

      dispatch(setMap(map))
      dispatch(setMarker(marker))
      dispatch(setMapState('success'))
    })
    map.on('click', (e: any) => {
      console.log(e)
      var features = map.queryRenderedFeatures(e.point, { layers: ['beacons'] });
      if (features.length > 0) {
        console.log(features[0].properties)
      }
      console.log(features)
      if (e.target.selectedObject) {
        console.log(e.target.selectedObject)
        Object3D.current = e.target.selectedObject
      }
    })

    return () => map.remove();
  }, [dispatch]);



  return <div>
    <div ref={mapContainer} style={{ height: '100vh', width: '100vw' }} >
    </div>
    <FloorSelector />
    <PopOver />
  </div>
    ;
};

export default MapboxComponent;