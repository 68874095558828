// import { number } from "mathjs";
import EventEmitter from "eventemitter3";
// import { log } from "console";
export class inertialNavigator extends EventEmitter {
    //存放三轴数据
    valueNum = 5;
    //用于存放计算阈值的波峰波谷差值
    tempValue: number[];
    tempCount = 0;
    //是否上升的标志位
    isDirectionUp = false;
    //持续上升次数
    continueUpCount = 0;
    //上一点的持续上升的次数，为了记录波峰的上升次数
    continueUpFormerCount = 0;
    //上一点的状态，上升还是下降
    lastStatus = false;
    //波峰值
    peakOfWave = 0;
    //波谷值
    valleyOfWave = 0;
    //此次波峰的时间
    timeOfThisPeak = 0;
    //上次波峰的时间
    timeOfLastPeak = 0;
    //当前的时间
    timeOfNow = 0;
    //当前传感器的值
    gravityNew = 0;
    //上次传感器的值
    gravityOld = 0;
    //动态阈值需要动态的数据，这个值用于这些动态数据的阈值
    initialValue = 1.3;
    //初始阈值
    ThreadValue = 1.3;

    //初始范围
    minValue = 6;
    maxValue = 15;
    private CountTimeState = 0;
    public TEMP_STEP = 0;
    private lastStep = -1;
    public average = 0;
    //每步的时间戳
    private stepTime: number[] = [];

    accelerationIncludingGravity: DeviceMotionEventAcceleration | null = null;
    accelerationIncludingGravitys: DeviceMotionEventAcceleration[] = [];
    orientation: {
        alpha: number;
        beta: number;
        gamma: number;
    }[] = [];
    timeOfLastAcc = 0;
    timeOfThisAcc = 0;

    // 添加x、y轴的峰值和波谷变量
    peakOfWaveX = 0;
    valleyOfWaveX = 0;
    peakOfWaveY = 0;
    valleyOfWaveY = 0;

    isDirectionUpX = false;
    isDirectionUpY = false;

    continueUpCountX = 0;
    continueUpCountY = 0;

    // 更新DetectorPeak状态时，考虑x、y轴
    lastStatusX = false;
    lastStatusY = false;

    continueUpFormerCountX = 0;
    continueUpFormerCountY = 0;
    gravityOldX = 0;
    gravityOldY = 0;
    // private orientationUpdateTime = 0;
    // EE: EventEmitter
    constructor() {
        super();
        this.tempValue = []

        this.tempValue = []
        window.addEventListener("devicemotion", (e) => {
            if (e.accelerationIncludingGravity) {
                this.accelerationIncludingGravity = e.accelerationIncludingGravity;
                // console.log(e.accelerationIncludingGravity.z,"m/s")
                this.timeOfThisAcc = Date.now();
            }
        })

        window.addEventListener("deviceorientation", (e) => {
            this.emit("orientation", {
                alpha: e.alpha,
                beta: e.beta,
                gamma: e.gamma
            })
        })

        // window.addEventListener("deviceorientation", (e) => {
        //     let now = Date.now()
        //     if(!e.alpha)
        //     return;
        // let alpha = e.alpha;
        // if(Math.abs(e.alpha - 90) <= 10){
        //     alpha = 90;
        // }
        // else if (Math.abs(e.alpha - 180) <= 10){
        //     alpha = 180;
        // }
        // else if (Math.abs(e.alpha - 270) <= 10){
        //     alpha = 270;
        // }
        // else if(360 - e.alpha <= 10 || e.alpha <= 10){
        //     alpha = 0;
        // }
        //     this.emit("orientation", {
        //         alpha: alpha,
        //         beta: e.beta,
        //         gamma: e.gamma,
        //         time: now
        //     })
        // })
        console.log("inertialNavigator")
    }
    start() {
        // while (true) {
        setInterval(() => {
            if (!this.accelerationIncludingGravity)
                return;
            if (this.accelerationIncludingGravitys.length < 2) {
                this.accelerationIncludingGravitys.push(this.accelerationIncludingGravity)
                return
            }
            else {
                this.accelerationIncludingGravitys.shift()
                this.accelerationIncludingGravitys.push(this.accelerationIncludingGravity)
                let xSum = 0;
                let ySum = 0;
                let zSum = 0;
                let count = 0;
                for (const acceleration of this.accelerationIncludingGravitys) {
                    if (acceleration.x && acceleration.y && acceleration.z) {
                        xSum += acceleration.x;
                        ySum += acceleration.y;
                        zSum += acceleration.z;
                        count++;
                    }
                }
                const xAverage = xSum / count;
                const yAverage = ySum / count;
                const zAverage = zSum / count;
                this.calc_step({
                    x: xAverage,
                    y: yAverage,
                    z: zAverage
                })

            }
        }, 50)
        // }
    }
    calc_step(acc: DeviceMotionEventAcceleration | null) {
        if (!acc?.z || !acc?.x || !acc?.y)
            return;
        this.average = acc.z;
        // console.log( this.average)
        this.detectorNewStep(this.average)
        if (this.gravityOldX === 0) {
            this.gravityOldX = acc.x;
        }
        if (this.gravityOldY === 0) {
            this.gravityOldY = acc.y;
        }
        this.DetectorPeak( acc.x, this.gravityOldX, 'x');
        this.DetectorPeak(acc.y, this.gravityOldY, 'y');
        this.gravityOldY = acc.y;
        this.gravityOldX = acc.x;
    }

    /*
     * 检测步子，并开始计步
     * 1.传入sersor中的数据
     * 2.如果检测到了波峰，并且符合时间差以及阈值的条件，则判定为1步
     * 3.符合时间差条件，波峰波谷差值大于initialValue，则将该差值纳入阈值的计算中
     * */
    detectorNewStep(value: number) {
        if (this.gravityOld === 0) {
            this.gravityOld = value
        }
        else {
            if (this.DetectorPeak(value, this.gravityOld)) {
                this.timeOfLastPeak = this.timeOfThisPeak;
                this.timeOfNow = Date.now()
                if (this.timeOfNow - this.timeOfLastPeak >= 300
                    && (this.peakOfWave - this.valleyOfWave >= this.ThreadValue)
                    && (this.timeOfNow - this.timeOfLastPeak <= 2000)) {
                    let z_change = Math.abs(this.peakOfWave - this.valleyOfWave);
                    let y_change = Math.abs(this.peakOfWaveY - this.valleyOfWaveY);
                    let x_change = Math.abs(this.peakOfWaveX - this.valleyOfWaveX);
                    this.timeOfThisPeak = this.timeOfNow;
                    if(z_change >= x_change && z_change >= y_change){
                        this.emit('stepLength', 0.407 - 0.00013175 * (this.timeOfNow - this.timeOfLastPeak) + 0.18018 * Math.sqrt(this.peakOfWave - this.valleyOfWave))
                        this.emit('step', true)
                        this.TEMP_STEP++;
                    }
                }
                console.log('z:',this.peakOfWave - this.valleyOfWave,'x:',this.peakOfWaveX - this.valleyOfWaveX,'y:',this.peakOfWaveY - this.valleyOfWaveY)
                if (this.timeOfNow - this.timeOfLastPeak >= 300
                    && (this.peakOfWave - this.valleyOfWave >= this.initialValue)) {
                    this.timeOfThisPeak = this.timeOfNow;
                    this.ThreadValue = this.Peak_Valley_Thread(this.peakOfWave - this.valleyOfWave);
                }
            }
        }
        this.gravityOld = value
    }
    DetectorPeak(newValue: number, oldValue: number, axis: string = 'z') {
        if (axis === 'x') {
            this.lastStatusX = this.isDirectionUpX
            if (newValue >= oldValue) {
                this.isDirectionUpX = true;
                this.continueUpCountX++;
            }
            else {
                this.continueUpFormerCountX = this.continueUpCountX;
                this.continueUpCountX = 0;
                this.isDirectionUpX = false;
            }
            if (!this.isDirectionUpX && this.lastStatusX && (this.continueUpFormerCountX >= 2)) {
                this.peakOfWaveX = oldValue;
                // console.log(oldValue)
                return true;
            }
            else if (!this.lastStatusX && this.isDirectionUpX) {
                this.valleyOfWaveX = oldValue;
                return false
            }
            else {
                return false
            }
        }
        else if (axis === 'y') {
            this.lastStatusY = this.isDirectionUpY
            if (newValue >= oldValue) {
                this.isDirectionUpY = true;
                this.continueUpCountY++;
            }
            else {
                this.continueUpFormerCountY = this.continueUpCountY;
                this.continueUpCountY = 0;
                this.isDirectionUpY = false;
            }
            if (!this.isDirectionUpY && this.lastStatusY && (this.continueUpFormerCountY >= 2)) {
                this.peakOfWaveY = oldValue;
                return true;
            }
            else if (!this.lastStatusY && this.isDirectionUpY) {
                this.valleyOfWaveY = oldValue;
                return false
            }
            else {
                return false
            }
        }
        else {
            console.log("oldValue:",oldValue)
            this.lastStatus = this.isDirectionUp
            if (newValue >= oldValue) {
                this.isDirectionUp = true;
                this.continueUpCount++;
            }
            else {
                this.continueUpFormerCount = this.continueUpCount;
                this.continueUpCount = 0;
                this.isDirectionUp = false;
            }

            if (!this.isDirectionUp && this.lastStatus && (this.continueUpFormerCount >= 2 && (oldValue > this.minValue && oldValue < this.maxValue))) {
                this.peakOfWave = oldValue;
                // console.log(oldValue)
                return true;
            }
            else if (!this.lastStatus && this.isDirectionUp) {
                this.valleyOfWave = oldValue;
                return false
            }
            else {
                return false
            }
        }

    }

    Peak_Valley_Thread(value: number) {
        let tempThread = this.ThreadValue;
        if (this.tempCount < this.valueNum) {
            this.tempValue[this.tempCount] = value;
            this.tempCount++;
        }
        else {
            tempThread = this.averageValue(this.tempValue, this.valueNum);
            this.tempValue.shift()
            this.tempValue[this.valueNum - 1] = value
        }
        return tempThread;
    }

    averageValue(value: number[], n: number) {
        let ave = 0;
        for (let i = 0; i < n; i++) {
            ave += value[i]
        }
        ave = ave / this.valueNum;
        if (ave >= 8) {
            ave = 4.3
        }
        else if (ave >= 7) {
            ave = 3.3
        }
        else if (ave >= 4) {
            ave = 2.2
        }
        else if (ave >= 3) {
            ave = 1.4
        }
        else {
            ave = 1.3
        }
        return ave;
    }
    getStep() {
        return this.TEMP_STEP;
    }
    setStep(step: number) {
        if (step >= 0) {
            this.stepTime = []
            this.stepTime.push(Date.now())
            this.TEMP_STEP = step
        }
    }
    getStepTime() {
        return this.stepTime
    }

}