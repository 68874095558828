// import { compose, configureStore } from '@reduxjs/toolkit';
import { combineReducers,createStore } from 'redux';
import { allFloorsReducer, beaconsReducer, buidingDataReducer, drawingReducer, floorReducer, mapReducer, mapStateReducer } from './reducers/mapReducer';
import { composeWithDevTools } from "redux-devtools-extension";
// import { composeWithDevTools } from '@reduxjs/toolkit/dist/devtoolsExtension';
import { applyMiddleware } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
const rootReducer = combineReducers({
  map: mapReducer,
  allFloors: allFloorsReducer,
  floor: floorReducer,
  mapStates: mapStateReducer,
  drawing: drawingReducer,
  buildingData: buidingDataReducer,
  beacons: beaconsReducer
  // Add other reducers here
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;