import { FC, useEffect, useState } from "react";
import { Button, Form, Selector } from 'antd-mobile'
import { getFingerprint, getFingerprintList } from "../../../utils/request";
import ObsClient from 'esdk-obs-browserjs'
import * as turf from '@turf/turf'
import { useSelector } from "react-redux";
export interface props {
    setCancel: () => void;
}
const CreateFingerprint: FC<props> = ({ setCancel }) => {
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState([])
    const [allData, setAllData] = useState<any[]>([]);
    const map = useSelector((state: any) => state.map.map as mapboxgl.Map)
    const obsClient = new ObsClient({
        //推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险。
        //您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
        access_key_id: 'TWRHS7BEB4P4SZCS63AB',
        secret_access_key: 'kISdQfc6igXQ8jvd1dpqSuJArPq60ucPHbmidkr8',
        server: 'https://obs.cn-south-1.myhuaweicloud.com'
    });
    useEffect(() => {
        getFingerprintList("HUTB").then(res => {
            setOptions(res.map((item: any) => ({ label: item, value: item })))
            res.forEach((item: any) => {
                getFingerprint("HUTB", item).then(res => {
                    setAllData((allData) => [...allData, { label: item, value: res }])
                })
            })
        })
    }, [])
    useEffect(() => {
        selected.forEach((item: any) => {
            allData.forEach((data) => {
                if (data.label == item) {
                    console.log(data.value);
                    return;
                }
            })
        })
    }, [selected])
    function mapToObject<K extends string | number | symbol, V>(map: Map<K, V>): Record<string, V> {
        return Array.from(map.entries()).reduce((obj, [key, value]) => {
            // 将非字符串类型的键转化为字符串（可能丢失原始类型信息）
            const keyStr = key.toString();
            obj[keyStr] = value;
            return obj;
        }, {} as Record<string, V>);
    }

    function onFinish(values: any): void {
        if (selected.length === 0) {
            return;
        }
        let all : { fingerprints: any; coord: any[]; direction: number}[] = [];
        let Fingerprints: { fingerprints: any; coord: any[]; direction: number}[] = [];
        selected.forEach((item: any) => {
            const fingerprints: any = [];
            allData.forEach((data) => {
                if (data.label == item) {
                    const beacons = data.value.beacons;
                    const stepTime = data.value.step;
                    var point1 = turf.point(data.value.start);
                    var point2 = turf.point(data.value.end);
                    var bearing = turf.rhumbBearing(point1, point2);
                    const direction = turf.bearingToAzimuth(bearing);
                    if (stepTime.length > 0) {
                        stepTime.forEach((timestamp: any, index: any) => {
                            fingerprints.push(new Map());
                            beacons.forEach((beacon: any) => {
                                // debugger;
                                if (beacon["timestamp"] >= timestamp && index < stepTime.length - 1 && beacon["timestamp"] <= stepTime[index + 1]) {
                                    for (const key in beacon) {
                                        if (key === "timestamp" || key === "compassDirection") {
                                            continue;
                                        }
                                        if (fingerprints[fingerprints.length - 1].has(key)) {
                                            const rssiArray = fingerprints[fingerprints.length - 1].get(key)
                                            if (rssiArray) {
                                                const newArr = rssiArray?.concat(beacon[key])
                                                fingerprints[fingerprints.length - 1].set(key, newArr)
                                            }
                                            else {
                                                fingerprints[fingerprints.length - 1].set(key, beacon[key])
                                            }
                                        }
                                        else {
                                            fingerprints[fingerprints.length - 1].set(key, beacon[key])
                                        }
                                    }
                                }
                                else if (index === stepTime.length - 1 && beacon["timestamp"] >= timestamp) {
                                    for (const key in beacon) {
                                        if (key === "timestamp" || key === "compassDirection") {
                                            continue;
                                        }
                                        // if(!fingerprints.current[fingerprints.current.length-1]){
                                        //     fingerprints.current[fingerprints.current.length-1] = new Map<string,number[]>();
                                        //     console.log(beacon[key], key)
                                        //     fingerprints.current[fingerprints.current.length-1].set(key, beacon[key])
                                        // }
                                        if (fingerprints[fingerprints.length - 1].has(key)) {
                                            const rssiArray = fingerprints[fingerprints.length - 1].get(key)
                                            const newArr = rssiArray?.concat(beacon[key])
                                            fingerprints[fingerprints.length - 1].set(key, newArr)
                                        }
                                        else {
                                            fingerprints[fingerprints.length - 1].set(key, beacon[key])
                                        }
                                    }
                                }

                            })
                        })
                        console.log("fingerprints", fingerprints)
                        const start = data.value.start;
                        const end = data.value.end;
                        const deltaX = (end[0] - start[0]) / (fingerprints.length - 1);
                        const deltaY = (end[1] - start[1]) / (fingerprints.length - 1);
                        for (let i = 0; i < fingerprints.length; i++) {
                            if (fingerprints[i].size === 0 || fingerprints[i].size === 1) {
                                continue;
                            }
                            let excellent = 0;
                            for (let [key, value] of fingerprints[i].entries()) {
                                let sum = value.reduce((total: any, num: any) => total + num, 0);
                                let average = sum / value.length;
                                if(average > -75){
                                    excellent++;
                                }
                                else{
                                    fingerprints[i].delete(key);
                                    continue;
                                }
                                fingerprints[i].set(key, average);
                            }
                            if(excellent < 2 ){
                                continue;
                            }

                            // fingerprints[i].forEach((item: any) => {
                            //     for (const key in item) {
                            //         let mean = item[key].reduce((acc: any, val: any) => acc + val, 0) / beacons[key].length
                            //         item[key] = mean;
                            //     }
                            // })

                            Fingerprints.push({
                                "fingerprints": mapToObject(fingerprints[i]),
                                "coord": [start[0] + deltaX * i, start[1] + deltaY * i],
                                'direction': direction
                            });

                            (map.getSource('fingerprintPoint') as mapboxgl.GeoJSONSource).setData({
                                type: 'FeatureCollection',
                                features: [
                                    ...(map.getSource('fingerprintPoint') as any)._data.features,
                                    {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [start[0] + deltaX * i, start[1] + deltaY * i],
                                        },
                                        properties: {
                                            
                                        },
                                    }],
                            });
                            
                        }

                    }
                }
            })
            obsClient.putObject({
                Bucket: 'mitu',
                Key: `HUTB/fingerprints/fingerprints.json`,
                Body: Fingerprints
            }, (err: any, result: any) => {
                if (err) {
                    console.error('Error-->' + err);
                    setCancel();
                    console.log(Fingerprints);
                } else {
                    console.log('Status-->' + result.CommonMsg.Status);
                    setCancel();
                    console.log(Fingerprints)
                }
            });
        })
    }

    return (
        <Form
            className="z-50 absolute bottom-0 w-full"
            onFinish={onFinish}
            footer={
                <Button block type='submit' color='primary' size='large'>
                    提交
                </Button>
            }
        >
            <Form.Item>
                <Selector
                    options={options}
                    multiple
                    onChange={(arr) => setSelected(arr as any)}
                />
            </Form.Item>

        </Form>
    )
}

export default CreateFingerprint;