import EventEmitter from "eventemitter3";

export class GPSLocastor extends EventEmitter{
    constructor(){
        super();
    }
    start(){
        navigator.geolocation.watchPosition((position)=>{
            this.emit('geolocation', position.coords);
        })
    }

}