// import React from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
import MapboxComponent from './features/map/Map';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VConsole from 'vconsole';

function App() {
  const vConsole = new VConsole();
  return (
    <Router>
    <Routes>
      <Route 
        path="/"
        element={<MapboxComponent/>}
      />
    </Routes>
  </Router>
  );
}

export default App;
